// This file has been generated. DO NOT EDIT.
/**
 * Apollo 3 no longer merges incoming data automatically. This means every object in operations must have id
 * Or we can explicitly declare types that we want to merge in this file.
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
 * For arrays, we normally want the incoming array to replace the existing one
 * unless the incoming array is carrying updates so by default we replace it to avoid warnings in consoles
 * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
 */
import { TypePolicies } from '@apollo/client';

export const typePolicies: TypePolicies = {
    Query: {
        merge: true,

        fields: {
            adminProjectNotes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            applicants: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            contestUpgrades: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designPreviews: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designers: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            eligibleProducts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            filesByWorkItem: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            getCollaborations: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            industries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            productCategories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            productCounts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            products: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            productsByKeys: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            productsByStatus: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectCancellationReasons: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectDeliverables: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectRecentEventFeed: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            receivedDisputes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            recentChatEvents: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            recentChats: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            recentDraftStructuredBriefs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            recentLaunchedStructuredBriefs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            rootProductGroups: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            submittedDisputes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            supportedCategories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            topProducts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CheckProducerHasAssetResponse: { merge: true },
    Mutation: {
        merge: true,

        fields: {
            addPayPalPayoutMethod: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            cancelProjects: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            deletePayoutMethod: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectDeleteFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectDeleteFilesFromVariation: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectUpdateFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectUserFlagAdd: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    GetCollaborationWorkItemsForUserResponse: {
        merge: true,

        fields: {
            workItems: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CollaborationWorkItem: { merge: true },
    CollaborationListItem: { merge: true },
    Revisions: { merge: true },
    Appointment: { merge: true },
    GetCollaborationResponse: { merge: true },
    CollaborationResponse: {
        merge: true,

        fields: {
            deliveryVersions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CollaborationPermissions: { merge: true },
    DeliveryVersion: { merge: true },
    Delivery: {
        merge: true,

        fields: {
            variations: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DeliveryVariation: {
        merge: true,

        fields: {
            surfaceViews: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    WorkEntity: { merge: true },
    WorkEntityMerchandising: { merge: true },
    WorkEntityProduct: { merge: true },
    WorkEntityDesign: { merge: true },
    SurfaceView: { merge: true },
    Collaboration: {
        merge: true,

        fields: {
            events: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    GetEventFeedResponse: {
        merge: true,

        fields: {
            events: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Event: { merge: true },
    CreateDeliveryContent: { merge: true },
    AppointmentContent: { merge: true },
    StartCollaborationContent: { merge: true },
    RevisionRequest: {
        merge: true,

        fields: {
            variationComments: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DeliveryVariationSurfaceComment: { merge: true },
    SurfaceComment: { merge: true },
    RequestRevisionContent: { merge: true },
    CollaborationFile: { merge: true },
    CareforceBriefUrlResponse: { merge: true },
    GenericRepsonse: { merge: true },
    CollaboratePrintProduct: { merge: true },
    ContestOverviewContest: {
        merge: true,

        fields: {
            entries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            winningEntries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designers: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            deliverableFileTypes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            relatedContests: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            inspiringDesigns: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            blogPosts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            inspirationTermLinks: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewDesigner: {
        merge: true,

        fields: {
            designs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewCategory: { merge: true },
    ContestOverviewIndustry: { merge: true },
    ContestOverviewContestPackage: { merge: true },
    ContestOverviewWinningEntry: { merge: true },
    ContestOverviewDesign: { merge: true },
    ContestOverviewContestEntry: { merge: true },
    Money: { merge: true },
    ContestOverviewRelatedContest: { merge: true },
    ContestOverviewBrief: {
        merge: true,

        fields: {
            elements: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewTextElement: { merge: true },
    ContestOverviewTextAreaElement: { merge: true },
    ContestOverviewMultipleChoiceImageElement: {
        merge: true,

        fields: {
            value: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            choices: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewMultipleChoiceImageElementChoice: { merge: true },
    ContestOverviewSlidersElement: {
        merge: true,

        fields: {
            sliders: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewSlidersElementSlider: { merge: true },
    ContestOverviewFilesElement: {
        merge: true,

        fields: {
            urls: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewChoiceElement: {
        merge: true,

        fields: {
            choices: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestOverviewChoiceElementChoice: { merge: true },
    ContestPrizeAward: {
        merge: true,

        fields: {
            deliverableItems: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestEntry: { merge: true },
    ContestDeliverableItem: { merge: true },
    ContestOverviewBlogPost: { merge: true },
    ContestOverviewInspirationTermLink: { merge: true },
    Contest: {
        merge: true,

        fields: {
            entrantIds: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            winnerIds: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            finalistIds: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContestEntryDispute: { merge: true },
    ContestUpgrade: {
        merge: true,

        fields: {
            bundledUpgradeKeys: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    StructuredBriefValidationError: { merge: true },
    StructuredBriefFulfilment: { merge: true },
    StructuredBrief: {
        merge: true,

        fields: {
            designers: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designersWithSource: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            errors: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            industries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            contacts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            foreignKeys: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            requiresCertifications: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            dynamicFields: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            groups: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PaymentSummaryItem: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PaymentSummaryItemOption: { merge: true },
    PaymentSummary: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Group: { merge: true },
    RelevantDesignersResponse: {
        merge: true,

        fields: {
            hits: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DesignerWithSource: { merge: true },
    LaunchPermissions: {
        merge: true,

        fields: {
            designerPermissions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    LaunchPermission: { merge: true },
    PartnerCoupon: {
        merge: true,

        fields: {
            validPackages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    InputField: { merge: true },
    TextAreaField: { merge: true },
    SelectChoiceField: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    MultiSelectChoiceField: {
        merge: true,

        fields: {
            multiChoiceValues: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    GridChoiceField: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    MultiGridChoiceField: {
        merge: true,

        fields: {
            multiGridChoiceValue: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PrintServiceOptionsField: { merge: true },
    RadioChoiceField: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    MultiChoiceField: {
        merge: true,

        fields: {
            multiChoiceValue: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ChoiceOption: {
        merge: true,

        fields: {
            price: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            optionDetails: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    MultiInputField: {
        merge: true,

        fields: {
            multiInputValue: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    BudgetField: { merge: true },
    DateField: { merge: true },
    FilesField: {
        merge: true,

        fields: {
            filesValue: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            allowedFileTypes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ClientConfiguredField: { merge: true },
    WorkEntityField: { merge: true },
    SquarespacePackageChoiceField: { merge: true },
    LogoContestTierChoiceField: { merge: true },
    ContestUpgradesChoiceField: {
        merge: true,

        fields: {
            contestUpgradesChoiceValues: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    StructuredBriefFile: { merge: true },
    CreatorsResponse: {
        merge: true,

        fields: {
            hits: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CreatorHit: {
        merge: true,

        fields: {
            expertiseCategories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categoriesWithValues: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            expertiseIndustries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            certifications: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            locales: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            topClients: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            flags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designCategories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ImageSources: { merge: true },
    CreatorDesign: { merge: true },
    DesignCategory: { merge: true },
    ExpertDesign: {
        merge: true,

        fields: {
            cropCoordinates: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            tools: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            tags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            adminTags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Profile: {
        merge: true,

        fields: {
            curationSamples: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            expertiseCategories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            portfolioDesigns: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CurationDesign: {
        merge: true,

        fields: {
            crop: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DesignerApplicationEligibilityInfo: { merge: true },
    User: {
        merge: true,

        fields: {
            certifications: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designerCategoryPreferences: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designerSkills: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            earningStats: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            languages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            links: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            payoutMethods: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectFlags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            softwareTools: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DeliverableVariation: {
        merge: true,

        fields: {
            deliverableItems: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            otherFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Deliverable: {
        merge: true,

        fields: {
            deliverableItems: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            deletedFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            otherFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            variations: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    RequiredFile: { merge: true },
    FileDeliverableItemFile: {
        merge: true,

        fields: {
            validations: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DeliverableItem: {
        merge: true,

        fields: {
            requiredFiles: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    CustomerReview: { merge: true },
    Upload: { merge: true },
    Images: {
        merge: true,

        fields: {
            uploads: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PinComment: { merge: true },
    RevisionInformation: { merge: true },
    MerchantData: { merge: true },
    AppointmentData: { merge: true },
    DeliverableView: { merge: true },
    DocRef: {
        merge: true,

        fields: {
            previewUrls: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DocRefPreviewUrl: { merge: true },
    Link: { merge: true },
    PreviewInstructions: { merge: true },
    DesignProductResponse: { merge: true },
    DesignProduct: {
        merge: true,

        fields: {
            services: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DesignPriceResponse: { merge: true },
    DesignService: { merge: true },
    PagingInfo: { merge: true },
    VPProductCategory: { merge: true },
    PrintProductConfigurator: {
        merge: true,

        fields: {
            productOptions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            compatibleOptions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PrintProductOption: {
        merge: true,

        fields: {
            values: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductOptionValue: { merge: true },
    CompatibleOptionsEntry: {
        merge: true,

        fields: {
            value: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ViewLinkImage: { merge: true },
    ViewLink: { merge: true },
    View: { merge: true },
    GetAllViewsResponse: {
        merge: true,

        fields: {
            views: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContentModel: {
        merge: true,

        fields: {
            inclusions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            heroImages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            faqs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    SimpleLink: { merge: true },
    Inclusion: { merge: true },
    MerchandisingProductView: { merge: true },
    CloudinaryImage: { merge: true },
    FAQ: { merge: true },
    Overview: { merge: true },
    MultimediaComponent: { merge: true },
    File: { merge: true },
    Handover: { merge: true },
    HandoverContextualHelp: { merge: true },
    HandoverFileScribbleResult: { merge: true },
    HandoverFileScribble: {
        merge: true,

        fields: {
            scribblePaths: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    HandoverScribblePath: {
        merge: true,

        fields: {
            scribblePathCoords: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    HandoverScribblePathCoord: { merge: true },
    ExpertDesigns: {
        merge: true,

        fields: {
            designs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Opportunity: {
        merge: true,

        fields: {
            applicants: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Match: { merge: true },
    Applicant: {
        merge: true,

        fields: {
            featuredDesigns: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Job: { merge: true },
    JobBrief: { merge: true },
    JobProduct: {
        merge: true,

        fields: {
            requiredCertifications: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categoryExperience: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    JobExperience: { merge: true },
    JobPermissions: { merge: true },
    JobFlags: { merge: true },
    Application: { merge: true },
    ApplicationData: {
        merge: true,

        fields: {
            portfolio: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PortfolioItem: { merge: true },
    JobEntity: { merge: true },
    Subscription: { merge: true },
    PostMessagePayload: { merge: true },
    SetLastSeenAtPayload: { merge: true },
    SetLastReadAtPayload: { merge: true },
    PublishUserIsTypingPayload: { merge: true },
    UpdateMessagePayload: { merge: true },
    DeleteMessagePayload: { merge: true },
    Chat: {
        merge: true,

        fields: {
            participants: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ChatParticipant: { merge: true },
    ChatViewer: { merge: true },
    EventFeed: {
        merge: true,

        fields: {
            events: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ChatEvent: { merge: true },
    ChatEventMessageContent: { merge: true },
    ChatEventFileMessageContent: { merge: true },
    ChatEventFile: { merge: true },
    ChatEventAnnotation: { merge: true },
    ChatEventExternalEventContent: { merge: true },
    ChatEventMessageWithFilesContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    UserTypedInChat: { merge: true },
    OrderItem: { merge: true },
    Order: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    VistaprintProduct: {
        merge: true,

        fields: {
            attributes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            compatibleAttributes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Attribute: {
        merge: true,

        fields: {
            values: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    AttributeValue: { merge: true },
    Print: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PrintSummary: {
        merge: true,

        fields: {
            options: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    EstimatedPrice: { merge: true },
    PrintOption: { merge: true },
    CategoryAndLocale: { merge: true },
    PrintDesign: {
        merge: true,

        fields: {
            previews: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ValidateDesignResponse: { merge: true },
    DesignPreview: { merge: true },
    Template: { merge: true },
    PrintProductRecommendationsResponse: {
        merge: true,

        fields: {
            recommendedProducts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    RecommendedProduct: { merge: true },
    SaveProductDraftResponse: { merge: true },
    DeleteProductDraftResponse: { merge: true },
    PublishProductDraftResponse: { merge: true },
    ArchiveProductResponse: { merge: true },
    LocaleMpvId: { merge: true },
    ProductPackageImage: {
        merge: true,

        fields: {
            points: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductPackageHeaderVideo: { merge: true },
    ProductPackageDetails: { merge: true },
    PriceRange: { merge: true },
    Marketing: { merge: true },
    CategoryExperience: { merge: true },
    CimpressProductConfig: { merge: true },
    ProductFixedPriceProject: {
        merge: true,

        fields: {
            details: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            additionalDetails: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            requiredCertifications: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categoryExperience: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            fulfilment: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductContest: {
        merge: true,

        fields: {
            details: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductQuotedProject: {
        merge: true,

        fields: {
            details: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    FixedPrice: {
        merge: true,

        fields: {
            amounts: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductCategory: {
        merge: true,

        fields: {
            children: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductLocalizationStats: {
        merge: true,

        fields: {
            translatedLanguages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProductGroup: {
        merge: true,

        fields: {
            productGroups: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PrintProduct: { merge: true },
    ServiceProduct: { merge: true },
    PriceDetail: { merge: true },
    ExpertServicePrice: { merge: true },
    ExpertService: { merge: true },
    KeyStrValueInt: { merge: true },
    CreateAssetForProjectResponse: { merge: true },
    ProjectEditMessagePayload: { merge: true },
    ProjectDeleteMessagePayload: { merge: true },
    ProjectFilesResult: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFilesFilteredResult: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    AllActiveFilteredProjectFilesResult: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFileMessagesResult: {
        merge: true,

        fields: {
            messages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFileScribbleResult: { merge: true },
    ProjectDeleteFolderResult: { merge: true },
    ProjectFolderStatsResult: {
        merge: true,

        fields: {
            foldersWithStats: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            projectFileStageStats: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFolderWithStats: {
        merge: true,

        fields: {
            folderFileStageStats: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectCancellation: { merge: true },
    ProjectFileStageStats: { merge: true },
    ProjectFilesIDSetResult: {
        merge: true,

        fields: {
            filesIdSet: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ActiveUnpaidProjectClonesResult: {
        merge: true,

        fields: {
            projects: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectDraft: { merge: true },
    Project: {
        merge: true,

        fields: {
            folders: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            stages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            invoices: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            deliverables: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectEvent: { merge: true },
    ProjectFolder: { merge: true },
    ProjectEventFeed: {
        merge: true,

        fields: {
            events: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectUserStatus: { merge: true },
    ProjectEventPermissions: { merge: true },
    ProjectPermissions: { merge: true },
    ProjectMessagePermissions: { merge: true },
    ProjectFileMessagePermissions: { merge: true },
    ProjectFileMessage: { merge: true },
    ProjectFileMessageAnnotation: { merge: true },
    ProjectFile: {
        merge: true,

        fields: {
            messages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            downloadedBy: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFileScribble: {
        merge: true,

        fields: {
            scribblePaths: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ScribblePath: {
        merge: true,

        fields: {
            scribblePathCoords: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ScribblePathCoord: { merge: true },
    UploadedProjectFile: { merge: true },
    ProjectFilePermissions: { merge: true },
    ProjectInvoice: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectInvoicePermissions: { merge: true },
    ProjectInvoiceItem: {
        merge: true,

        fields: {
            releaseRequests: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectInvoiceItemPermissions: { merge: true },
    ProjectInvoiceReleaseRequest: { merge: true },
    ProjectInvoiceItemReleaseRequest: { merge: true },
    ProjectInvoiceItemReleaseRequestPermissions: { merge: true },
    ProjectEventUnknown: { merge: true },
    AuthoriseAvailableForProfileContent: { merge: true },
    RevokeAvailableForProfileContent: { merge: true },
    StartAutoFinishProjectTimerContent: { merge: true },
    CancelAutoFinishProjectTimerContent: { merge: true },
    AutoFinishProjectReminderContent: { merge: true },
    FinishProjectRequest: { merge: true },
    DesignTransferAgreementSignedContent: { merge: true },
    ProjectDescriptionCreatedContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFileMessageCreatedContent: {
        merge: true,

        fields: {
            fileMessages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectCreatedContent: { merge: true },
    ProjectMessageContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectCancelledContent: { merge: true },
    ProjectUncancelledContent: { merge: true },
    ProjectUnfinishedContent: { merge: true },
    ProjectFinishedContent: { merge: true },
    ProjectBookInVistaCareConsultationContent: { merge: true },
    ProjectCompletion: { merge: true },
    ProjectLockedContent: { merge: true },
    ProjectUnlockedContent: { merge: true },
    ProjectDetailsEditedContent: { merge: true },
    ProjectTitleUpdatedContent: { merge: true },
    ProjectFolderCreatedContent: { merge: true },
    ProjectFolderUpdatedContent: { merge: true },
    ProjectFolderDeletedContent: { merge: true },
    ProjectFileUploadedContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectFileStageChangesContent: {
        merge: true,

        fields: {
            stageChanges: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            folderChanges: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            folderStageChanges: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    FileStageChanges: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    FileFolderChanges: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    FileFolderStageChanges: {
        merge: true,

        fields: {
            stages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectDeliverableFileUploadedContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectDeliverableFinalFileUploadedContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectMarkDeliverableFileContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProjectUnmarkDeliverableFileContent: {
        merge: true,

        fields: {
            files: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    NoDesignerResponseContent: { merge: true },
    InvoiceRevisionRequestedContent: { merge: true },
    InvoiceRevisedContent: { merge: true },
    InvoicePaymentHeldContent: { merge: true },
    InvoiceResentContent: { merge: true },
    InvoiceExpiredContent: { merge: true },
    InvoiceExpirationReminderContent: { merge: true },
    InvoiceRefundedContent: { merge: true },
    InvoiceAutoReleaseTimerStartedContent: { merge: true },
    InvoiceAutoReleaseTimerCancelledContent: { merge: true },
    InvoiceCancelledContent: { merge: true },
    InvoiceCreatedContent: { merge: true },
    InvoiceUpdatedContent: { merge: true },
    InvoicePaidContent: { merge: true },
    InvoiceItemReleaseRequestDeclinedContent: { merge: true },
    SuggestProjectCompletionContent: { merge: true },
    InvoiceItemReleaseRequestedContent: { merge: true },
    InvoiceItemPaymentReleasedContent: { merge: true },
    InvoicePaymentReleasedContent: { merge: true },
    ProjectStageUpdatedContent: {
        merge: true,

        fields: {
            stages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DesignTransferAgreement: { merge: true },
    InvoicesStats: { merge: true },
    DesignTransferAgreementSignature: { merge: true },
    ProjectInvoiceReleaseResult: { merge: true },
    ProjectInvoiceItemReleaseResult: { merge: true },
    DeclineProjectInvoiceItemReleaseRequestResult: { merge: true },
    RequestProjectInvoiceItemReleaseResult: { merge: true },
    ProjectInvoiceCancelResult: { merge: true },
    ProjectInvoiceRefundResult: { merge: true },
    Note: { merge: true },
    UnlockProjectResult: { merge: true },
    LockProjectResult: { merge: true },
    YourProjectsResult: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourProjectsStatsResult: { merge: true },
    YourProjectsItemProjectDraft: { merge: true },
    YourProjectsProjectDraftContextMessageActionsProjectDraft: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourProjectsItemProject: { merge: true },
    YourProjectsProjectContextMessageActionsCompletedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourProjectsProjectContextMessageActionsInvitedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    AuthoriseAddDesignsToProfileResult: { merge: true },
    RevokeAddDesignsToProfile: { merge: true },
    YourProjectsProjectContextMessageActionsStartedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourProjectsProjectContextMessageActionsLockedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourProjectsProjectContextMessageActionsCanceledProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    MarkProjectAsTestResult: { merge: true },
    UnmarkProjectAsTestResult: { merge: true },
    ScopeOfWork: { merge: true },
    ProjectStage: { merge: true },
    ProjectDeliverable: { merge: true },
    Request: { merge: true },
    RequestFacts: { merge: true },
    Dates: {
        merge: true,

        fields: {
            dates: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    AppointmentDate: { merge: true },
    Times: {
        merge: true,

        fields: {
            times: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Timeslot: { merge: true },
    ServicesResponse: {
        merge: true,

        fields: {
            services: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Service: {
        merge: true,

        fields: {
            fileTypes: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    FileType: { merge: true },
    Price: { merge: true },
    Dev: {
        merge: true,

        fields: {
            tracesWithErrors: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Trace: { merge: true },
    DesignerCategoryPreference: { merge: true },
    Design: {
        merge: true,

        fields: {
            crop: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            toolTags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designTags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Site: {
        merge: true,

        fields: {
            availableUserLanguages: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            categories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            industries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            designTags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            toolTags: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            acceptedLocales: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ParentCategory: {
        merge: true,

        fields: {
            priceRange: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            children: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Category: {
        merge: true,

        fields: {
            priceRange: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            children: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Industry: { merge: true },
    Regions: {
        merge: true,

        fields: {
            regions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Region: {
        merge: true,

        fields: {
            locations: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    TimezoneFullPathAndRegion: { merge: true },
    Countries: {
        merge: true,

        fields: {
            countries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    Country: { merge: true },
    Viewer: { merge: true },
    OAuthProviderProfile: { merge: true },
    Address: { merge: true },
    PortfolioDesignsResult: {
        merge: true,

        fields: {
            results: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PortfolioDesignsPagination: { merge: true },
    ReviewSummary: {
        merge: true,

        fields: {
            starSummaries: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    StarRatingSummary: { merge: true },
    TopClient: { merge: true },
    Review: { merge: true },
    ReviewResult: {
        merge: true,

        fields: {
            results: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ReviewPagination: { merge: true },
    Locale: { merge: true },
    SocialMedia: { merge: true },
    RatingRequestDetails: { merge: true },
    RatingRequestResponse: { merge: true },
    DesignerSkill: { merge: true },
    DesignerSkills: {
        merge: true,

        fields: {
            designerSkills: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PortfolioDesignOrder: { merge: true },
    SlackToken: { merge: true },
    NotificationSetting: { merge: true },
    NotificationSettings: {
        merge: true,

        fields: {
            settings: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    EarningHistory: {
        merge: true,

        fields: {
            history: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PayoutHistory: {
        merge: true,

        fields: {
            history: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    EarningBalance: { merge: true },
    EarningChangeSource: { merge: true },
    EarningChange: {
        merge: true,

        fields: {
            sources: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    PayoutMethod: { merge: true },
    Payout: { merge: true },
    PayoneerConnection: { merge: true },
    EarningStatistic: { merge: true },
    Invite: { merge: true },
    RecommendationCategoryResult: {
        merge: true,

        fields: {
            categories: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ProfileTerms: { merge: true },
    VistaUserAuthenticationMethod: { merge: true },
    DiscoverDesigns: {
        merge: true,

        fields: {
            designs: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    OrderWorkSubItemProject: { merge: true },
    ContextMessageAndActionsCompletedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsLockedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsStartedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsInvitedProject: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    OrderWorkSubItemContest: { merge: true },
    ContextMessageAndActionsOnHoldContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsRefundedContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsLockedContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsOpenContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsSelectingFinalistContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsFinalRoundContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsSelectingWinnerContest: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsWonContestMultipleWinners: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    ContextMessageAndActionsWonContestSingleWinner: {
        merge: true,

        fields: {
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    OrderWorkItem: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    WorkItemCollaborator: { merge: true },
    WorkItemStage: { merge: true },
    WorkItemIcon: { merge: true },
    WorkItemAction: { merge: true },
    StandardWorkItem: {
        merge: true,

        fields: {
            featuredDesignerAvatars: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            collaborators: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
            actions: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    YourWorkItemsResult: {
        merge: true,

        fields: {
            items: {
                merge(_existing = [], incoming: any[]) {
                    return incoming;
                },
            },
        },
    },
    DeleteYourWorkItemResult: { merge: true },
};
