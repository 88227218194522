import { isSsr } from '../isSsr';

interface SetCookieParameters {
    cookieName: string;
    value?: string;
    expiryInMinutes: number;
    path?: string;
}

// Set a cookie with a default value of *, and valid on all paths
export function setCookieByName({
    cookieName,
    expiryInMinutes,
    value = '*',
    path = '/',
}: SetCookieParameters) {
    //We can't set cookies on the server from JS
    if (isSsr()) {
        return;
    }
    const date = new Date();
    date.setTime(date.getTime() + 60 * 1000 * expiryInMinutes);
    document.cookie = `${cookieName}=${value};path=${path};expires=${date.toUTCString()};domain=.${
        window.location.hostname
    }`;
}
