import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { bugtracker } from '@99designs/design-services-common';
import { BriefFileInput } from '@99designs/graph-utils/types';
import { BriefSetTextAreaMutation } from './text/briefSetText.generated';

export function equalityCheck<T>(a: T, b: T): boolean {
    return deepEqual(a, b);
}

function deepEqual<T>(a: T, b: T): boolean {
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.length === b.length && a.every((val, index) => deepEqual(val, b[index]));
    }

    // Check if both are objects (and not null)
    if (typeof a === 'object' && typeof b === 'object' && a !== null && b !== null) {
        const keysA = Object.keys(a);
        const keysB = Object.keys(b);

        // If the number of keys is different, they are not equal
        if (keysA.length !== keysB.length) {
            return false;
        }

        // Check if all keys and their corresponding values are equal
        return keysA.every((key) =>
            deepEqual((a as Record<string, unknown>)[key], (b as Record<string, unknown>)[key])
        );
    }

    // Fallback for primitive values
    return a === b;
}

export function useUpdateField<T extends string | string[] | BriefFileInput | BriefFileInput[]>({
    key,
    setField,
    briefId,
    initialValue,
}: {
    key: string;
    setField: ReturnType<
        typeof useMutation<
            // all of the field mutations have the same shape, so it's safe to use the first one
            BriefSetTextAreaMutation,
            {
                briefId: string;
                key: string;
                value: T;
            }
        >
    >[0];
    briefId?: string;
    initialValue?: T | null;
}) {
    const [previousValue, setPreviousValue] = useState(initialValue);
    const form = useFormContext();
    const setError = form?.setError;

    const updateInputField = useCallback(
        (value: T) => {
            if (!briefId) {
                return;
            }

            // Something is causing the physical product to get unset, which then fails validation
            // A brief can be created without a product, but there MUST be one by the time a user
            // gets to the review page or cart
            // This is a temporary hack to prevent SetField from unsetting the product
            if (key === 'physicalProduct' && value === '') {
                // Temporary notification to track occurances
                bugtracker.notify(new Error('Cannot unset physicalProduct'));
                return;
            }

            if (equalityCheck(value, previousValue)) {
                return;
            }

            setPreviousValue(value);

            return setField({
                variables: {
                    briefId,
                    key,
                    value,
                },
                onError: (error) => {
                    const invalidInputError = error.graphQLErrors
                        .filter((e) => e.extensions?.type === 'INVALID_INPUT')
                        .pop();
                    if (invalidInputError && setError) {
                        setError(key, {
                            message: invalidInputError.message,
                        });
                    }
                },
                update: (cache, { data }) => {
                    if (data && data.setField) {
                        cache.modify({
                            id: cache.identify(data.setField),
                            fields: {
                                dynamicFields: () => data.setField.dynamicFields,
                            },
                        });
                    }
                },
            });
        },
        [briefId, setField, previousValue, key, setError]
    );

    if (!briefId) {
        return () => null;
    }

    return updateInputField;
}
