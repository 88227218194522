import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    SWAN_BASE_URL_MAP,
    SwanProvider,
    SwanSSRProvider,
    getRootClassNames,
    useBrowserClasses,
} from '@vp/swan';

interface SwanConfigurationProps {
    children: React.ReactNode;
}

export const swanBaseUrl = SWAN_BASE_URL_MAP.default.cdn;
export const swanRootClassName = getRootClassNames();

export const SwanConfiguration = ({ children }: SwanConfigurationProps) => {
    const browser = useBrowserClasses();
    return (
        <SwanProvider swanBaseUrl={swanBaseUrl}>
            <HelmetProvider>
                <SwanSSRProvider>
                    <Helmet
                        htmlAttributes={{ class: browser }}
                        bodyAttributes={{ class: swanRootClassName }}
                    />
                    {children}
                </SwanSSRProvider>
            </HelmetProvider>
        </SwanProvider>
    );
};
