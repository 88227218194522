import { useTracking } from '@vp/react-tracking';
import { usePage } from '@99designs/tracking';

// NOTE: Please refer to the guideline for 'Navigation Clicked' tracking event in the documentation for `eventDetail` and `navigationDetail` values
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2218560827/Navigation+Tracking+Framework
interface UseNavigationClickedProps {
    label: string;
}

export const useNavigationClicked = ({ label }: UseNavigationClickedProps) => {
    const { tracking } = useTracking();
    const { pageData } = usePage();

    const trackNavigationClick = (eventDetail: string, navigationDetail: string) => {
        tracking.track('Navigation Clicked', {
            category: pageData.pageSection,
            label,
            eventDetail,
            navigationDetail,
            pageSection: pageData.pageSection,
            pageStage: pageData.pageStage,
            pageName: pageData.pageName,
        });
    };

    return { trackNavigationClick };
};
