import React, { ReactNode, createContext, useContext, useState } from 'react';
import { RecentlySelectedProduct } from '../BriefForm/FormFields/ClientConfiguredFields/ProductDropdown/utils';
import { BriefProductFragment } from '../BriefForm/brief.generated';

const MAX_RECENT_PRODUCTS = 16;

export type NormalisedBriefProduct = Omit<
    BriefProductFragment,
    'fulfilmentStrategy' | 'name' | '__typename' | 'pricing'
> & {
    pricing: Omit<BriefProductFragment['pricing'], 'earningsInUsd'>;
};

interface BriefContextProps {
    product?: NormalisedBriefProduct;
    updateProduct: (product?: NormalisedBriefProduct) => void;

    recentProducts?: RecentlySelectedProduct[];
    addRecentProduct: (product: RecentlySelectedProduct) => void;
    isUpdatingProduct: boolean;
    setIsUpdatingProduct: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BriefContext = createContext<BriefContextProps | undefined>(undefined);

interface BriefProviderProps {
    children: ReactNode;
}

export const BriefProvider: React.FC<BriefProviderProps> = ({ children }) => {
    const [product, setProduct] = useState<NormalisedBriefProduct>();

    // TODO: Recent products should be stored in the browser's local storage.

    const [recentProducts, setRecentProducts] = useState<RecentlySelectedProduct[]>([]);

    const updateProduct = (newProduct?: NormalisedBriefProduct) => {
        setProduct(newProduct);
    };

    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);

    return (
        <BriefContext.Provider
            value={{
                product,
                updateProduct,
                recentProducts,
                addRecentProduct: (product: RecentlySelectedProduct) => {
                    setRecentProducts((prev) => {
                        if (prev.length >= MAX_RECENT_PRODUCTS) {
                            prev.splice(MAX_RECENT_PRODUCTS - 1, 1);
                        }

                        const index = prev.findIndex((p) => p.mpvId === product.mpvId);

                        if (index !== -1) {
                            prev.splice(index, 1);
                        }

                        return [product, ...prev];
                    });
                },
                isUpdatingProduct,
                setIsUpdatingProduct,
            }}
        >
            {children}
        </BriefContext.Provider>
    );
};

export const useBriefContext = () => {
    const context = useContext(BriefContext);

    if (!context) {
        throw new Error('useBriefContext must be used within a BriefProvider');
    }

    return context;
};
