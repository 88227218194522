import { useCallback } from 'react';
import {
    DS_API_REQUESTOR,
    buildError,
    getBriefBaseUrl,
    getBriefMultiStepUrl,
    getCountryCode,
} from '@99designs/design-services-common';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';
import { MERCHANT_SERVICE_CONFIG } from '../../components/Submit/consts';
import { isMultiStepBriefEnabled } from './isMultiStepBriefEnabled';
import { useAutoFulfillment } from './useAutoFulfillment';

export const CARE_DESIGN_CART_IMAGE =
    'https://cdn.dst.vistaprint.io/DefaultCartImages/CAREDesignCartImage.png';
export const DEFAULT_CART_IMAGE =
    'https://cdn.dst.vistaprint.io/DefaultCartImages/ExpertServicesCartImage.png';

interface User {
    accessToken: string;
    shopperId: string;
}

export type FulfilmentDataType = FulfilThroughAbacusData | FulfilThroughRequestsData;
export type CartBody = {
    defaultBehaviorsToIgnore: string[];
    product: {
        productKey: string;
        productVersion: number;
        productDisplayName: string;
        selectedAttributes?: Record<string, string>[];
    };
    quantity: number;
    customOrderData: {
        fulfillment: FulfilmentDataType & { environment: string; expertProductPRD: string };
        analytics: {
            workId?: string;
        };
        ux: { modRef: { family: string; id: string } }[];
        checkout?: { itemAuthorityKey: string };
    };
    design: {
        livePreviewUrl: string;
        editDocumentUrl: string;
    };
};

export interface FulfilThroughRequestsData {
    productBriefID: string;
    printProductMPVId: string;
    postPaymentUrl: string;
    experiments: string;
    expertRequestID: string;
    fulfilmentStrategy?: FulfilmentStrategy;
    withDesignLive?: boolean;
}

interface FulfilThroughAbacusData {
    productBriefID: string;
    orderKey: string;
    siteKey: string;
    postPaymentUrl: string;
    fulfillerId: string; //NOTE: required field, do not alter.
    productKey: string;
    fulfilmentStrategy?: FulfilmentStrategy;
}

export interface UpsertToCartRequest {
    product: {
        productKey: string;
        productVersion: number;
        productName: string;
        productImage?: string;
        selectedAttributes?: Record<string, string>[];
        fulfilmentData: FulfilmentDataType;
    };
    // Correlation ID is a unique identifier the caller supplies to the order management controller so it can determine whether an item is already in the cart
    correlationId: string;
    workId?: string;
}

interface CartLineItem {
    customOrderData?: {
        fulfillment?: {
            productBriefID?: string;
            requestAltId?: string;
            expertRequestID?: string;
        };
    };
}

interface GetCartResponse {
    lineItems?: CartLineItem[];
}

type CartHook = {
    upsertToCart: (payload: UpsertToCartRequest) => Promise<void>;
    isInCart: (briefId: string) => Promise<boolean>;
    requestAltIdByBriefId: (briefId: string) => Promise<string>;
    requestIdByBriefId: (briefId: string) => Promise<string>;
    canUseCart: boolean;
};

export function getTenantForCart(locale: string) {
    const cartEnv =
        process.env['NEXT_PUBLIC_VISTA_CART_ENV'] ||
        process.env['VITE_PUBLIC_VISTA_CART_ENV'] ||
        'DEV';
    const countryCode = getCountryCode(locale);
    return `VP-${countryCode}-${cartEnv}`;
}

export function getEnvForCart() {
    return process.env['NEXT_PUBLIC_REQUEST_ENV'] || 'NON_PRODUCTION';
}

export function getLivePreviewUrl(payload: UpsertToCartRequest['product']) {
    if (payload.productImage) {
        return payload.productImage;
    }
    // if withDesignLive exists then its a care product
    if ('withDesignLive' in payload.fulfilmentData) {
        return CARE_DESIGN_CART_IMAGE;
    }
    return DEFAULT_CART_IMAGE;
}

/**
 * Users that have a vistaprint email as their shopper id are not able to call the cart API successfully
 */
export function canUseCart(shopperId: string): boolean {
    if (shopperId.includes('@vistaprint.com') || shopperId.includes('@vista.com')) {
        return false;
    }
    return true;
}

export function useCart(locale: string, { accessToken, shopperId }: User): CartHook {
    const CART_TENANT = getTenantForCart(locale);
    const autoFulfillment = useAutoFulfillment();
    const VISTA_CART_URL = `https://vistacart.orders.vpsvc.com/tenants/${CART_TENANT}/carts/${shopperId}`;

    const getCart = useCallback(async (): Promise<GetCartResponse> => {
        if (!canUseCart(shopperId)) {
            return {};
        }
        try {
            const response = await fetch(VISTA_CART_URL, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    accept: 'application/json',
                    'content-type': 'application/json',
                },
            });
            handleResponseStatus(response);
            return (await response.json()) as GetCartResponse;
        } catch (e) {
            throw buildError(e, 'Failed to get cart');
        }
    }, [VISTA_CART_URL, accessToken]);

    const upsertToCart = useCallback(
        async ({ product, correlationId, workId }: UpsertToCartRequest) => {
            const isMultiStep = await isMultiStepBriefEnabled(
                product.fulfilmentData.fulfilmentStrategy
            );
            if (process.env['NEXT_PUBLIC_AUTO_FULFILLMENT'] === 'enabled') {
                await autoFulfillment(
                    transformProductForCart(product, isMultiStep, workId),
                    shopperId
                );
                return;
            }
            const ADD_TO_CART_URL = `${VISTA_CART_URL}/v2/items/correlationId/${correlationId}?requestor=${DS_API_REQUESTOR}`;
            try {
                const response = await fetch(ADD_TO_CART_URL, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        accept: 'application/json',
                        'content-type': 'application/json',
                    },
                    body: mapToCartBody(product, isMultiStep, workId),
                });

                handleResponseStatus(response);
            } catch (e) {
                throw buildError(e, 'Failed to upsert cart');
            }
        },
        [VISTA_CART_URL, accessToken, shopperId, autoFulfillment]
    );

    const isInCart = useCallback(
        async (briefId: string) => {
            const data = await getCart();
            const cartItems = data?.lineItems;
            const briefInCart = cartItems?.find((item: CartLineItem) => {
                return item?.customOrderData?.fulfillment?.productBriefID === briefId;
            });
            return !!briefInCart;
        },
        [getCart]
    );

    const requestAltIdByBriefId = useCallback(
        async (briefId: string) => {
            const data = await getCart();
            const cartItems = data?.lineItems;
            const briefInCart = cartItems?.find(
                (item: CartLineItem) =>
                    item?.customOrderData?.fulfillment?.productBriefID === briefId
            );
            return briefInCart?.customOrderData?.fulfillment?.requestAltId ?? '';
        },
        [getCart]
    );
    const requestIdByBriefId = useCallback(
        async (briefId: string) => {
            const data = await getCart();
            const cartItems = data?.lineItems;
            const foundCartItem = cartItems?.find(
                (item: CartLineItem) =>
                    item?.customOrderData?.fulfillment?.productBriefID === briefId
            );
            return foundCartItem?.customOrderData?.fulfillment?.expertRequestID ?? '';
        },
        [getCart]
    );

    return {
        isInCart,
        upsertToCart,
        requestAltIdByBriefId,
        requestIdByBriefId,
        canUseCart: canUseCart(shopperId),
    };
}

function transformProductForCart(
    payload: UpsertToCartRequest['product'],
    isMultiStep: boolean,
    workId?: string
): CartBody {
    const body: CartBody = {
        defaultBehaviorsToIgnore: ['PRODUCT_DISPLAY_NAME', 'PRODUCT_ATTRIBUTE_DISPLAY_NAMES'], //tells the API to ignore certain validations performed through MSX
        product: {
            productKey: payload.productKey,
            productVersion: payload.productVersion,
            productDisplayName: payload.productName,
            selectedAttributes: payload.selectedAttributes,
        },
        quantity: 1,
        customOrderData: {
            fulfillment: {
                ...payload.fulfilmentData,
                expertProductPRD: payload.productKey,
                environment: getEnvForCart(),
            },
            analytics: {
                workId: workId,
            },
            ux: [
                {
                    modRef: {
                        family: '99d-order-tools',
                        id: 'nnd-order-tools-order-confirmation',
                    },
                },
                {
                    modRef: {
                        family: '99d-order-tools',
                        id: 'nnd-order-tools-order-history',
                    },
                },
                {
                    modRef: {
                        family: '99d-order-tools',
                        id: 'nnd-order-tools-order-details',
                    },
                },
            ],
        },
        design: {
            livePreviewUrl: getLivePreviewUrl(payload),
            editDocumentUrl: getEditUrlForItem(payload.fulfilmentData.productBriefID, isMultiStep),
        },
    };

    if (payload.productKey === MERCHANT_SERVICE_CONFIG['DesignLive']) {
        body.customOrderData.checkout = {
            ...body.customOrderData.checkout,
            itemAuthorityKey: 'expertRequestsCartValidation',
        };
    }
    return body;
}

function mapToCartBody(
    payload: UpsertToCartRequest['product'],
    isMultiStep: boolean,
    workId?: string
) {
    return JSON.stringify(transformProductForCart(payload, isMultiStep, workId));
}

function getEditUrlForItem(briefId: string, isMultiStep: boolean) {
    if (isMultiStep) {
        return `${getBriefMultiStepUrl()}/${briefId}/review?isEditMode`;
    }
    // TODO: vary by environment
    return `${getBriefBaseUrl()}/${briefId}`;
}

interface CartResponse extends Response {
    message?: string;
    error?: string;
}

function handleResponseStatus(response: CartResponse) {
    if (response.status > 300 || response.status < 200) {
        throw new Error(`Error from Cart API: ${response.status}: ${response?.message}`);
    }
}
