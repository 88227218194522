export const DS_API_REQUESTOR = '99d-design-services-app'; // Requestor value provides identification of the source of the request to help correlate logs to your requests
export const APP_LOCALE = 'en-US';
export const APP_TENANT = 'vistaprint';
export const GRAPH_ENDPOINT = '/graph';
export const PREVIEWABLE_IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png', 'gif', 'svg'];
export const DS_BRIEF_BASE_URL = '/design/briefs';
export const DS_BRIEF_CREATE_URL = '/design/briefs/create';
export const DS_BRIEF_MULTI_STEP_URL = '/experts/request/brief';
export const CLOUDINARY_BASE_URL = 'https://cms.cloudinary.vpsvc.com/image/upload/';

// AUTH
export const IDENTITY_UNIFICATION_ID_99DESIGNS = '52hgX4jh3cAXz3o1j91hUA';
export const AUTH_CIMPRESS_INTERNAL_CLAIM_KEY = 'https://claims.cimpress.io/cimpress_internal';
export const AUTH_VP_ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'vp-access-token';
export const AUTH_UNIFIED_IDENTITIES_CLAIM = 'https://claims.cimpress.io/unified_identities';

// URL PARAMS
export const AUTH_INTERNAL_CLIENT_PARAM = 'useCareAuthClient';

// FEATURE FLAGS
export const BIFROST_ENABLED_LOCALES = ['en-US', 'en-AU'];
