import { useNavigate } from 'react-router-dom';
import { Button } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useNavigationClicked, useStepContext } from '@99designs/swan-wizard';
import { useFieldProcessing } from './FieldProcessingProvider';

export const ReviewBriefButton = () => {
    const { processingFields } = useFieldProcessing();
    const isProcessing = processingFields.length > 0;
    const nav = useNavigate();
    const { validate, currentStep } = useStepContext();

    const { trackNavigationClick } = useNavigationClicked({
        label: 'Content: Bottom Bar',
    });

    return (
        <Button
            skin="primary"
            type="submit"
            onClick={async () => {
                const isValid = await validate(currentStep);
                if (!isValid) {
                    return;
                }
                const currentPath = window.location.pathname;
                trackNavigationClick(
                    `${window.location.pathname}${window.location.search};${currentPath}/review;Bottom Bar;designBriefNextButton`,
                    'designBriefNextButton'
                );

                nav(`${currentPath}/review`);
            }}
            disabled={isProcessing}
        >
            {__('Next')}
        </Button>
    );
};
