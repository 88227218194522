import { useEffect } from 'react';
import { getCookieByName, setCookieByName } from '@99designs/browser';

export default function useSetupCtcLocally(isLocal: boolean) {
    useEffect(() => {
        if (isLocal) {
            const userContext = getCookieByName('userContext', document.cookie);
            if (!userContext) {
                // Since this will be set each time before the CTC lib fires a warning, we can set expiry to a short time.

                setCookieByName({
                    cookieName: 'userContext',
                    value: '{}',
                    expiryInMinutes: 5,
                });
            }
        }
    }, [isLocal]);
}
