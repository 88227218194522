import Bugsnag from '@bugsnag/js';
import { getCurrentCulture as vpGetCurrentCulture } from '@vp/culture-resolution';

export const DEFAULT_DEVELOPMENT_LOCALE = 'en-US';

export function getCurrentCulture() {
    try {
        return vpGetCurrentCulture();
    } catch (e) {
        Bugsnag.notify(new Error('Failed to get current culture'));

        return DEFAULT_DEVELOPMENT_LOCALE;
    }
}
