import { useEffect, useState } from 'react';
import {
    UseFormClearErrors,
    UseFormRegister,
    UseFormSetError,
    UseFormSetValue,
} from 'react-hook-form';
import {
    Box,
    Column,
    FlexBox,
    FluidImage,
    GridContainer,
    Row,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    Typography,
    tokensRaw,
} from '@vp/swan';
import { ChoiceOption } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { useFieldViewSkinContext } from '../../../../context/FieldViewContext';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { FormInput } from '../../BriefForm';
import { Field_MultiGridChoiceField_Fragment } from '../../brief.generated';
import { useUpdateMultiGridChoiceField } from './useUpdateMultiGridChoiceField';

export type MultiGridChoiceFieldProps = Field_MultiGridChoiceField_Fragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
    setError: UseFormSetError<FormInput>;
};

export function Input({
    id,
    required,
    options,
    multiGridChoiceValue,
    maxSelections,
    register,
    setValue,
    clearErrors,
    setError,
}: MultiGridChoiceFieldProps) {
    const initialValues = setInitialValues(multiGridChoiceValue, options);
    const [selectedValues, setSelectedValues] = useState(initialValues);
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateMultiGridChoiceField(id, briefId, multiGridChoiceValue);

    useEffect(() => {
        const selectedValuesArray = filterSelectedValues(selectedValues);

        setValue(id, selectedValuesArray);

        if (required) {
            // If this field is required, you cannot update with empty array, it will error from the server side.
            if (selectedValuesArray.length > 0) {
                updateMutation(selectedValuesArray);
            }
        } else {
            updateMutation(selectedValuesArray);
        }
    }, [selectedValues, setValue, id, updateMutation, required]);

    const isDisabled = (value: string) => {
        const numOfSelected = getNumOfSelected(selectedValues);
        return numOfSelected === maxSelections && !selectedValues[value];
    };

    const handleOnSelectedValuesChange = (values: Record<string, boolean>) => {
        setSelectedValues(values);

        // react-hook-form register does not trigger onChange with SWAN SelectionSet
        // Clear errors if there are selected values
        // Set error if there are no selected values and the field is required
        // This is to prevent the error message from showing on page load
        // and only show when the user has interacted with the field
        // and there are no selected values
        const numOfSelected = getNumOfSelected(values);
        if (numOfSelected > 0) {
            clearErrors(id);
        } else if (numOfSelected === 0 && required) {
            setError(id, { type: 'required', message: __('This field is required') });
        }
    };

    return (
        <SelectionSet
            variant="multi-select"
            selectedValues={selectedValues}
            onSelectedValuesChange={handleOnSelectedValuesChange}
        >
            <GridContainer>
                <Row
                    marginY={5}
                    style={{ marginLeft: `-16px`, marginRight: `-24px` }}
                    paddingRight={6}
                >
                    {options.map(({ value, image, label, subtext }) => (
                        <Column
                            span={4}
                            spanXs={6}
                            paddingRight={0}
                            paddingBottom={2}
                            paddingLeft={5}
                            key={`selection-set-${id}-value-${value}`}
                        >
                            <SelectionSetInput
                                {...register(id, {
                                    required: required ? __('This field is required') : false,
                                })}
                                value={value}
                                marginRight={'4'}
                                disabled={isDisabled(value)}
                            >
                                <SelectionSetLabel
                                    paddingX={0}
                                    paddingTop={0}
                                    marginBottom={0}
                                    style={{ width: '100%', overflow: 'hidden' }}
                                >
                                    {image && <FluidImage src={image} alt={label} />}
                                    <Box padding={'5'}>
                                        <Typography fontWeight="bold">{label}</Typography>
                                        {subtext && (
                                            <Typography fontSize="small">{subtext}</Typography>
                                        )}
                                    </Box>
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        </Column>
                    ))}
                </Row>
            </GridContainer>
        </SelectionSet>
    );
}

interface MultiGridChoiceFieldViewProps {
    options: Field_MultiGridChoiceField_Fragment['options'];
    multiGridChoiceValue: Field_MultiGridChoiceField_Fragment['multiGridChoiceValue'];
    colWidthOverride?: NonNullable<1 | 2 | 4 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12>;
}

export function View(props: MultiGridChoiceFieldViewProps) {
    const skin = useFieldViewSkinContext();

    const valueToChoiceData = Object.fromEntries(
        props.options.map((option) => {
            return [option.value, option];
        })
    );

    const choiceData = props.multiGridChoiceValue.map((value) => {
        return valueToChoiceData[value];
    });

    return (
        <SelectionSet>
            <GridContainer>
                <Row>
                    {choiceData.map(({ value, image, label, subtext }) => (
                        <Column
                            span={props.colWidthOverride ?? 4}
                            spanXs={6}
                            paddingLeft={5}
                            paddingBottom={1}
                            paddingRight={0}
                            key={value}
                        >
                            <SelectionSetInput value={value} marginRight={'4'}>
                                <SelectionSetLabel
                                    padding={0}
                                    style={{
                                        overflow: 'hidden',
                                        pointerEvents: 'none',
                                        cursor: 'text',
                                    }}
                                >
                                    {image && <FluidImage src={image} alt={label} />}
                                    <Box padding={'5'}>
                                        <Typography fontWeight="bold">{label}</Typography>
                                        {subtext && (
                                            <Typography fontSize={skin.fontSize}>
                                                {subtext}
                                            </Typography>
                                        )}
                                    </Box>
                                </SelectionSetLabel>
                            </SelectionSetInput>
                        </Column>
                    ))}
                </Row>
            </GridContainer>
        </SelectionSet>
    );
}

const ReviewImage = ({
    image,
    reviewImage,
    label,
}: Pick<Field_MultiGridChoiceField_Fragment['options'][0], 'image' | 'reviewImage' | 'label'>) => {
    if (reviewImage) {
        return (
            <FluidImage
                style={{
                    height: '48px',
                    width: '48px',
                    borderRadius: tokensRaw.SwanSemBorderRadiusSubtle,
                }}
                src={reviewImage}
                alt={label}
            />
        );
    }
    if (image) {
        return (
            <FluidImage
                style={{
                    height: '48px',
                    width: '48px',
                    borderRadius: tokensRaw.SwanSemBorderRadiusSubtle,
                }}
                src={image}
                alt={label}
            />
        );
    }
    return null;
};

export function Review(props: MultiGridChoiceFieldViewProps) {
    const valueToChoiceData = Object.fromEntries(
        props.options.map((option) => {
            return [option.value, option];
        })
    );

    const choiceData = props.multiGridChoiceValue.map((value) => {
        return valueToChoiceData[value];
    });

    return (
        <GridContainer>
            {choiceData.map(({ image, reviewImage, label, subtext }, i) => (
                <FlexBox
                    key={`multi-grid-choice-field-review-${label}`}
                    flexDirection="row"
                    gap="4"
                    mb={i === choiceData.length - 1 ? 0 : 5}
                >
                    <ReviewImage image={image} reviewImage={reviewImage} label={label} />
                    <FlexBox alignItems={'center'}>
                        <Typography
                            style={{
                                display: 'block',
                            }}
                            fontSkin={'body-standard-bold'}
                        >
                            {label}
                            {subtext && ':'}
                            {subtext && (
                                <span style={{ fontWeight: 'normal', marginLeft: '4px' }}>
                                    {subtext}
                                </span>
                            )}
                        </Typography>
                    </FlexBox>
                </FlexBox>
            ))}
        </GridContainer>
    );
}

const filterSelectedValues = (values: Record<string, boolean>): string[] => {
    return Object.keys(values).filter((key) => {
        return values[key];
    });
};

const setInitialValues = (multiGridChoiceValue: string[], options: Partial<ChoiceOption>[]) => {
    return options.reduce((acc, item) => {
        if (item?.value) {
            acc[item.value] = multiGridChoiceValue.includes(item.value);
        }
        return acc;
    }, {} as { [key: string]: boolean });
};

const getNumOfSelected = (values: Record<string, boolean>) => {
    return Object.values(values).filter((value) => value).length;
};
