import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Button,
    Icon,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogContent,
    ModalDialogFooter,
    Spinner,
    responsive,
    useScreenClass,
} from '@vp/swan';
import type { Dispatch, SetStateAction } from 'react';
import { __ } from '@99designs/i18n';
import { useBriefContext } from '../BriefContext';
import { useUpdateBriefMutation } from './brief.generated';
import { transformBriefFormData } from './getSubmit';

export function useEditState(setGroupFormKey: Dispatch<SetStateAction<number>>, briefId: string) {
    const [isOpen, setIsOpen] = useState(false);
    const form = useFormContext();
    const [currentValue, setCurrentValue] = useState(form.getValues());
    const setError = form?.setError;
    const { updateProduct, product } = useBriefContext();
    const [currentProduct, setCurrentProduct] = useState(product);

    const [updateBrief, { loading }] = useUpdateBriefMutation({
        update: (cache, { data }) => {
            if (!data) {
                return;
            }
            if (data.brief.errors) {
                data.brief.errors.forEach((error) => {
                    setError(error.field, { type: 'server', message: error.reason });
                });
            }
            if (data.brief.dynamicFields) {
                cache.modify({
                    id: cache.identify(data.brief),
                    fields: {
                        dynamicFields: () => data.brief.dynamicFields,
                    },
                });
            }
        },
    });

    const onSubmit = () => {
        updateProduct(currentProduct);
        return updateBrief({
            variables: { id: briefId, fields: { ...transformBriefFormData(currentValue) } },
        });
    };

    const open = () => {
        setCurrentValue(form.getValues());
        setIsOpen(true);
    };

    const cancel = async () => {
        await onSubmit();

        Object.keys(currentValue).forEach((key) => {
            const value = currentValue[key];

            if (key === 'productOptions') {
                const productOptions = JSON.parse(value);
                Object.keys(productOptions).forEach((productOptionKey) => {
                    form.setValue(`${key}.${productOptionKey}`, productOptions[productOptionKey]);
                });

                return;
            }

            form.setValue(key, value);
        });

        setGroupFormKey((prev) => prev + 1);

        setIsOpen(false);
    };

    const close = async () => {
        setIsOpen(false);

        setCurrentProduct(product);
    };

    return { isOpen, isSaving: loading, open, cancel, close };
}

const ResponsiveModalDialogContent = responsive(ModalDialogContent);

export function EditFormGroupModal({
    briefId,
    showEditButton,
    groupHasErrors,
    setGroupFormKey,
    children,
}: {
    briefId: string;
    showEditButton: boolean;
    groupHasErrors: boolean;
    setGroupFormKey: Dispatch<SetStateAction<number>>;
    children: React.ReactNode;
}) {
    const screenClass = useScreenClass();
    const { isOpen, isSaving, open, cancel, close } = useEditState(setGroupFormKey, briefId);
    const { trigger } = useFormContext();
    const { isUpdatingProduct: isUpdating } = useBriefContext();
    const dialogRef = React.useRef<HTMLDialogElement>(null);

    // Prevents the modal from closing when clicking outside the modal
    useEffect(() => {
        if (dialogRef.current) {
            dialogRef.current.addEventListener(
                'click',
                (e) => {
                    if (e.target === e.currentTarget) {
                        e.stopImmediatePropagation();
                    }
                },
                true
            );
        }
    }, []);

    useEffect(() => {
        if (!isOpen) trigger();
    }, [trigger, isOpen]);

    return (
        <>
            <ModalDialog ref={dialogRef} isOpen={isOpen} onRequestDismiss={() => null}>
                <ResponsiveModalDialogContent
                    aria-labelledby="modal dialog general"
                    lg={{ style: { minWidth: '800px' } }}
                    paddingBottom={0}
                >
                    <ModalDialogBody>{children}</ModalDialogBody>
                    <ModalDialogFooter
                        style={{
                            position: 'sticky',
                            height: '94px',
                            bottom: 0,
                            backgroundColor: 'white',
                            zIndex: 1000,
                        }}
                    >
                        <ModalDialogButtons>
                            <Button skin="secondary" disabled={isUpdating} onClick={cancel}>
                                {isSaving && <Spinner accessibleText={__('Loading...')} />}{' '}
                                {__('Cancel')}
                            </Button>
                            <Button
                                disabled={groupHasErrors || isUpdating}
                                skin="primary"
                                onClick={close}
                            >
                                {__('Save')}
                            </Button>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ResponsiveModalDialogContent>
            </ModalDialog>
            {showEditButton && (
                <Button
                    style={
                        screenClass === 'xs'
                            ? {
                                  marginLeft: 'auto',
                                  position: 'absolute',
                                  top: '4px',
                                  right: '4px',
                                  width: '44px',
                                  height: '44px',
                              }
                            : {
                                  alignSelf: 'flex-start',
                                  marginLeft: 'auto',
                                  width: '44px',
                                  height: '44px',
                              }
                    }
                    skin={'tertiary'}
                    onClick={open}
                    data-testid={'edit-button'}
                >
                    <Icon iconType="edit" />
                </Button>
            )}
        </>
    );
}
