import { UseFormRegister } from 'react-hook-form';
import { Box, Dropdown, DropdownOption, Typography } from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { BoxedLoader } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';
import { useDesignLiveAvailableTimesQuery } from './appointments.generated';

interface TimeSelectorProps {
    timezone: string;
    date: string | null;
    setTime: (time: string) => void;
    time: string;
    defaultValue: string;
    immutable: boolean | null;
    register: UseFormRegister<any>;
    id: string;
}

export default function TimeSelector({
    timezone,
    setTime,
    date,
    time,
    defaultValue,
    immutable,
    register,
    id,
}: TimeSelectorProps) {
    const { locale } = usePage();
    const disabled = !!immutable && defaultValue !== '';
    const { data, loading, error } = useDesignLiveAvailableTimesQuery({
        skip: !date,
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            date: date!,
            timezone: timezone,
            locale: locale,
        },
        onCompleted: (d) => {
            if (!defaultValue) return;

            const times = d.setField.times;

            const defaultTimeInAvailableTimes = times.find((d) => d.startTimeUTC === defaultValue);

            if (defaultTimeInAvailableTimes) {
                setTime(defaultValue);
            }
        },
        onError: (e) => {
            bugtracker.notify(`Failed to get available design live times: ${e.message}`);
        },
    });

    if (error) {
        return (
            <Box marginTop="5">
                <Typography fontSize="small" textColor="error">
                    {__(
                        'There was an error fetching available times. Please refresh the page to try again.'
                    )}
                </Typography>
            </Box>
        );
    }

    if (loading) {
        return <BoxedLoader label={__('Loading available times...')} />;
    }

    if (data?.setField?.times.length === 0) {
        return (
            <Typography fontSize="small">
                {__(`There are no available times. Please select another date.`)}
            </Typography>
        );
    }

    return (
        <Dropdown
            fullWidth
            value={time}
            {...register(id, { required: __('This field is required') })}
            onChange={(e) => setTime(e.target.value)}
            disabled={disabled}
        >
            <DropdownOption value="">Select a time</DropdownOption>
            {data?.setField?.times.map((t) => (
                <DropdownOption key={t.startTimeUTC} value={t.startTimeUTC}>
                    {UTCToTimeString(t.startTimeUTC)} - {UTCToTimeString(t.endTimeUTC)}
                </DropdownOption>
            ))}
        </Dropdown>
    );
}

const UTCToTimeString = (datetime: string) => {
    return new Date(datetime).toLocaleTimeString('default', {
        hour: 'numeric',
        minute: '2-digit',
        hourCycle: 'h12',
    });
};
