import React from 'react';
import { FlexBox, Typography } from '@vp/swan';
import { __ } from '@99designs/i18n';
import IconContainer from './components/IconContainer';
import NumberIcon from './components/NumberIcon';
import StepDescriptionBox from './components/StepDescriptionBox';
import StepIconWrapper from './components/StepIconWrapper';
import StepItemContainer from './components/StepItemContainer';
import StepTitleContainer from './components/StepTitleContainer';
import StepsContainer from './components/StepsContainer';
import Step1 from './images/Step1.png';
import Step2 from './images/Step2.png';
import Step3 from './images/Step3.png';

export type HowItWorksStep = {
    title: string;
    description: string;
    image: React.ReactNode;
};

export function HowItWorks({ isModal = false }: { isModal?: boolean }) {
    const steps: HowItWorksStep[] = [
        {
            title: __('Describe what you need'),
            description: __('Fill out this brief with your project details'),
            image: <img src={Step1} alt={__('step 1')} width={'90%'} height={'auto'} />,
        },
        {
            title: __('Submit your request'),
            description: __('Pay for your design & send your brief to our team'),
            image: <img src={Step2} alt={__('step 2')} width={'100%'} height={'auto'} />,
        },
        {
            title: __('Receive your design'),
            description: __('Get a draft from your designer within 24 hours'),
            image: <img src={Step3} alt={__('step 3')} width={'100%'} height={'auto'} />,
        },
    ];

    return (
        <div>
            <Typography fontSkin={'title-subsection'} textAlign="left" pr={8} pb={4}>
                {__('How it works')}
            </Typography>
            <StepsContainer isModal={isModal}>
                {steps.map(({ title, image, description }, index) => (
                    <StepItemContainer key={`how-it-works-${index}`} isModal={isModal}>
                        <StepIconWrapper>{image}</StepIconWrapper>
                        <FlexBox flexDirection={'column'} justifyContent={'center'}>
                            <StepTitleContainer isModal={isModal}>
                                <IconContainer>
                                    <NumberIcon num={index + 1} />
                                </IconContainer>
                                <Typography
                                    fontSkin={'body-standard-bold'}
                                    textAlign="left"
                                    marginLeft={2}
                                >
                                    {title}
                                </Typography>
                            </StepTitleContainer>
                            <StepDescriptionBox isModal={isModal}>
                                <Typography fontSkin={'body-small'}>{description}</Typography>
                            </StepDescriptionBox>
                        </FlexBox>
                    </StepItemContainer>
                ))}
            </StepsContainer>
        </div>
    );
}
