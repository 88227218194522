import { useIsReviewMode } from '../ViewContext';
import { FieldFragment } from '../brief.generated';

function isDisplayedInReviewMode(field: FieldFragment): boolean {
    if (field.__typename === 'ClientConfiguredField' && field.id === 'physicalProduct') {
        return false;
    }
    if (field.__typename === 'ClientConfiguredField' && field.id === 'productOptions') {
        return false;
    }
    return true;
}

export function useIsHiddenInReview(field: FieldFragment): boolean {
    const isReviewMode = useIsReviewMode();
    return isReviewMode === true && !isDisplayedInReviewMode(field);
}
