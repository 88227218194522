import { useEffect } from 'react';
import { UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { TextInput, Typography } from '@vp/swan';
import { format, parseISO } from 'date-fns';
import { __ } from '@99designs/i18n';
import { useBriefFormContext } from '../../../BriefContext/BriefFormContext';
import { FormInput } from '../../BriefForm';
import { DateFieldInputFragment } from './DateField.generated';
import { useUpdateDateField } from './useUpdateDateField';

export type DateFieldProps = DateFieldInputFragment & {
    register: UseFormRegister<FormInput>;
    setValue: UseFormSetValue<FormInput>;
    clearErrors: UseFormClearErrors<FormInput>;
};

export function Input({
    id,
    register,
    dateValue,
    setValue,
    required,
    clearErrors,
}: DateFieldProps) {
    const { briefId } = useBriefFormContext();
    const updateMutation = useUpdateDateField(id, briefId, dateValue);

    useEffect(() => {
        setValue(id, formatDate(dateValue) ?? '');
    }, [dateValue, id, setValue]);

    return (
        <TextInput
            type="date"
            data-testid={id}
            {...register(id, {
                required: required ? __('This field is required') : false,
                onBlur: (e) => {
                    setValue(id, e.target.value);
                    updateMutation(e.target.value);
                    clearErrors(id);
                },
            })}
            {...(dateValue ? { defaultValue: formatDate(dateValue) } : {})}
        />
    );
}

interface DateAreaFieldViewProps {
    dateValue: DateFieldInputFragment['dateValue'];
}

export function View({ dateValue }: DateAreaFieldViewProps) {
    if (!dateValue) {
        return null;
    }

    return <Typography style={{ whiteSpace: 'pre-wrap' }}>{formatViewDate(dateValue)}</Typography>;
}

const DATE_FORMAT = 'yyyy-MM-dd';

function formatDate(date: string) {
    const parsedDate = parseISO(date);
    return format(parsedDate, DATE_FORMAT);
}

function formatViewDate(dateString: string) {
    const date = new Date(dateString);
    const userLocale = navigator.language || 'en-US';
    return new Intl.DateTimeFormat(userLocale).format(date);
}
