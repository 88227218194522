import {
    Column,
    ColumnProps,
    GridContainer,
    Row,
    SelectedValue,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    Typography,
    responsive,
} from '@vp/swan';
import { __ } from '@99designs/i18n';

interface DateSelectorProps {
    date: string | null;
    setDate: (date: string | null) => void;
    defaultValue?: string;
    immutable: boolean | null;
    appointmentDates: Array<AppointmentDates>;
    colSpan?: ColumnProps['span'];
    colSpanXs?: ColumnProps['spanXs'];
}

export interface AppointmentDates {
    isAvailable: boolean;
    date: string;
}

const ResponsiveRow = responsive(Row);

export default function DateSelector({
    setDate,
    date,
    defaultValue,
    immutable,
    appointmentDates,
    colSpan = 3,
    colSpanXs = 12,
}: DateSelectorProps) {
    const disabled = !!immutable && defaultValue !== '';

    return (
        <SelectionSet
            variant="single-select"
            selectedValue={date}
            onSelectedValueChange={(newSelectedValue: SelectedValue) => {
                setDate(newSelectedValue);
            }}
        >
            <GridContainer>
                <ResponsiveRow
                    xs={{ style: { marginLeft: '-8px', marginRight: '-8px' } }}
                    md={{ style: { marginLeft: `-16px`, marginRight: `-24px` } }}
                >
                    {appointmentDates.map((d, index) => {
                        // Remove trailing Z so its not interpreted as UTC
                        const dateString = d.date.split('Z')[0];
                        const dateObj = new Date(dateString);
                        const dayName = dateObj.toLocaleDateString('default', {
                            weekday: 'short',
                        });
                        const month = dateObj.toLocaleDateString('default', { month: 'short' });
                        const day = dateObj.toLocaleDateString('default', { day: '2-digit' });

                        return (
                            <Column
                                span={colSpan}
                                spanXs={colSpanXs}
                                key={`${d.date}-${index}`}
                                marginBottom="4"
                            >
                                <SelectionSetInput
                                    value={d.date}
                                    marginRight={'4'}
                                    disabled={disabled || !d.isAvailable}
                                >
                                    <SelectionSetLabel>
                                        <Typography
                                            fontSize="small"
                                            fontWeight="bold"
                                            textAlign={'center'}
                                        >
                                            {`${day} ${month}`}
                                        </Typography>
                                        <Typography fontSize="small" textAlign={'center'}>
                                            {dayName}
                                        </Typography>
                                    </SelectionSetLabel>
                                </SelectionSetInput>
                            </Column>
                        );
                    })}
                </ResponsiveRow>
            </GridContainer>
        </SelectionSet>
    );
}
