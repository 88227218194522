import { __ } from '@99designs/i18n';
import { useIsReviewMode } from '../ViewContext';
import { FieldGroupData } from './FieldGroup';

/**
 * TODO: add extra schema fields for this in the future
 */
export function reviewGroupTitleWithOverrides(group: FieldGroupData) {
    if (group.id === 'chooseYourPhysicalProduct') {
        return __("What's your design for?");
    }
    return group.title;
}

export function useGroupTitleWithOverrides(group: FieldGroupData) {
    const isReviewMode = useIsReviewMode();
    return isReviewMode ? reviewGroupTitleWithOverrides(group) : group.title;
}
