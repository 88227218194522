export function getCookieByName(cookieName: string, cookieString?: string): string {
    if (!cookieString) {
        return '';
    }

    try {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(cookieString);
        const cookieArray = decodedCookie.split(';');
        for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            // eslint-disable-next-line eqeqeq
            while (cookie.charAt(0) == ' ') {
                cookie = cookie.substring(1);
            }
            // eslint-disable-next-line eqeqeq
            if (cookie.indexOf(name) == 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }
        return '';
    } catch (e) {
        return '';
    }
}
