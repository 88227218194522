import { useTracking } from '@vp/react-tracking';
import React, { useCallback, useEffect } from 'react';
import {
    Box,
    Button,
    FlexBox,
    H1,
    Icon,
    Link,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import { __vistaRoute } from '@99designs/design-services-common';
import { __ } from '@99designs/i18n';
import { usePage } from '@99designs/tracking';

export interface VistaLogoProps {
    width?: string;
    height?: string;
}

export const VistaLogo = ({ width, height }: VistaLogoProps) => (
    <svg
        width={width ?? '26'}
        height={height ?? '23'}
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M13.2416 22.5L7.6366 12.602H18.8457L13.2416 22.5Z" fill="#006196" />
        <path d="M18.8459 12.6003H7.63422L13.2393 2.70065L18.8459 12.6003Z" fill="#2BA8E0" />
        <path d="M14.7693 0H25.9802L18.846 12.6003L13.2401 2.70066L14.7693 0Z" fill="#006196" />
        <path d="M11.7109 0L13.2393 2.70066L7.63503 12.6003L0.5 0H11.7109Z" fill="#6ECFF5" />
    </svg>
);

export const VistaLogoWithText = ({ width, height }: VistaLogoProps) => (
    <svg
        width={width || '55'}
        height={height || '41'}
        viewBox="0 0 55 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M52.7408 35.7828C52.5516 35.783 52.3667 35.8393 52.2095 35.9445C52.0522 36.0498 51.9298 36.1993 51.8575 36.3741C51.7852 36.5489 51.7664 36.7413 51.8034 36.9268C51.8404 37.1124 51.9316 37.2828 52.0655 37.4165C52.1993 37.5502 52.3698 37.6412 52.5554 37.6781C52.7409 37.7149 52.9333 37.6959 53.108 37.6235C53.2828 37.551 53.4322 37.4284 53.5373 37.2711C53.6424 37.1138 53.6985 36.9288 53.6985 36.7396C53.6982 36.4858 53.5972 36.2424 53.4176 36.063C53.2381 35.8836 52.9946 35.7828 52.7408 35.7828ZM52.7408 37.5052C52.5894 37.5052 52.4413 37.4603 52.3154 37.3762C52.1895 37.2921 52.0914 37.1725 52.0335 37.0326C51.9755 36.8927 51.9603 36.7388 51.9899 36.5902C52.0194 36.4417 52.0923 36.3053 52.1994 36.1982C52.3065 36.0912 52.4429 36.0183 52.5914 35.9887C52.7399 35.9592 52.8939 35.9743 53.0338 36.0323C53.1737 36.0902 53.2932 36.1884 53.3774 36.3143C53.4615 36.4402 53.5064 36.5882 53.5064 36.7396C53.5061 36.9426 53.4254 37.1372 53.2819 37.2807C53.1383 37.4242 52.9438 37.505 52.7408 37.5052Z"
            fill="black"
        />
        <path
            d="M53.1414 36.5594C53.1414 36.3855 53.0203 36.2863 52.7937 36.2863H52.3904V37.2084H52.546V36.8443H52.668L52.9639 37.2084H53.1551L52.8419 36.8443C53.0376 36.8179 53.1414 36.7132 53.1414 36.5594ZM52.5497 36.7159V36.4374H52.7937C52.9147 36.4374 52.9848 36.4902 52.9848 36.5766C52.9848 36.6631 52.9147 36.7159 52.7937 36.7159H52.5497Z"
            fill="black"
        />
        <path
            d="M4.30307 35.893L2.81466 31.4406H0.969391L3.24252 37.6664H5.18338L7.44558 31.4406H5.77874L4.30307 35.893Z"
            fill="black"
        />
        <path
            d="M13.2391 33.7974C12.3351 33.6672 12.0365 33.5115 12.0365 33.1191C12.0365 32.7268 12.3588 32.4764 12.9178 32.4764C13.2154 32.4764 13.4357 32.5356 13.5932 32.6585C13.7507 32.7814 13.8463 32.9689 13.8936 33.2257H15.4767C15.3229 31.7973 14.2869 31.2966 12.9059 31.2966C11.6314 31.2966 10.4416 31.9403 10.4416 33.2366C10.4416 34.451 11.0852 34.9271 12.7384 35.1774C13.6315 35.3085 14.0011 35.487 14.0011 35.9157C14.0011 36.3445 13.6915 36.5939 13.0243 36.5939C12.6501 36.5939 12.3934 36.5129 12.2223 36.3627C12.0511 36.2125 11.9655 35.9922 11.93 35.7127H10.3233C10.3824 37.0582 11.3583 37.7965 13.037 37.7965C14.6756 37.7965 15.6561 37.0937 15.6561 35.7491C15.6561 34.4046 14.7985 34.024 13.2391 33.7974Z"
            fill="black"
        />
        <path
            d="M18.3185 35.681V32.6659H19.624V31.4424H18.3194V30.0432H16.608V31.4424H15.8123V32.665H16.6134V35.8312C16.6134 37.1257 17.3144 37.7912 18.6162 37.7912C18.9714 37.7954 19.3248 37.7394 19.6613 37.6255V36.2945C19.4543 36.379 19.232 36.4193 19.0086 36.4129C18.5671 36.4165 18.3185 36.1798 18.3185 35.681Z"
            fill="black"
        />
        <path
            d="M24.9414 32.3646C24.6683 31.8712 23.9883 31.2986 22.8667 31.2986C21.1371 31.2986 19.7943 32.6004 19.7943 34.5403C19.7943 36.2782 20.8931 37.7948 22.8667 37.7948C23.7297 37.7948 24.549 37.4552 24.9414 36.7379V37.6655H26.5227V31.4415H24.9414V32.3646ZM24.9778 34.655C24.9555 34.9702 24.853 35.2745 24.6801 35.539C24.407 35.9623 23.9437 36.31 23.1808 36.31C21.889 36.31 21.3974 35.2176 21.3974 34.5804V34.5676C21.3974 33.7838 21.9436 32.7879 23.168 32.7879C24.0729 32.7879 24.5645 33.2731 24.7967 33.7456C24.9393 34.0264 25.0019 34.341 24.9778 34.655Z"
            fill="black"
        />
        <path d="M9.75405 31.4415H8.04807V37.6665H9.75405V31.4415Z" fill="black" />
        <path
            d="M8.79729 28.9272C8.61706 28.9455 8.44573 29.0146 8.30318 29.1264C8.16062 29.2382 8.0527 29.3881 7.99194 29.5588C7.93118 29.7294 7.92007 29.9138 7.9599 30.0905C7.99973 30.2673 8.08887 30.4291 8.21696 30.5572C8.34506 30.6852 8.50686 30.7744 8.68358 30.8142C8.86031 30.854 9.0447 30.8429 9.21536 30.7822C9.38603 30.7214 9.53596 30.6135 9.64774 30.4709C9.75953 30.3284 9.82859 30.157 9.84692 29.9768C9.86078 29.8355 9.84316 29.693 9.79531 29.5593C9.74746 29.4257 9.67059 29.3043 9.57021 29.2039C9.46984 29.1035 9.34846 29.0267 9.21481 28.9788C9.08116 28.931 8.93857 28.9133 8.79729 28.9272Z"
            fill="black"
        />
        <path
            d="M35.3958 31.3805H36.3845V32.5011C36.7777 31.7974 37.3139 31.3095 38.4345 31.2731V32.2034C37.1828 32.2626 36.3845 32.6586 36.3845 34.1935V37.6136H35.3958V31.3805Z"
            fill="black"
        />
        <path
            d="M39.1538 29.8812C39.1538 29.7515 39.1923 29.6248 39.2643 29.517C39.3363 29.4092 39.4387 29.3252 39.5584 29.2756C39.6782 29.226 39.81 29.213 39.9371 29.2383C40.0643 29.2636 40.1811 29.326 40.2727 29.4177C40.3644 29.5093 40.4268 29.6261 40.4521 29.7533C40.4774 29.8804 40.4644 30.0122 40.4148 30.132C40.3652 30.2517 40.2812 30.3541 40.1734 30.4261C40.0656 30.4982 39.9389 30.5366 39.8093 30.5366C39.6359 30.5349 39.4702 30.4654 39.3476 30.3428C39.2251 30.2202 39.1555 30.0545 39.1538 29.8812ZM39.3359 31.3805H40.3245V37.6136H39.3359V31.3805Z"
            fill="black"
        />
        <path
            d="M41.7802 31.3806H42.7688V32.372C43.0556 31.7875 43.7465 31.2795 44.7716 31.2795C46.0588 31.2795 46.9637 31.9832 46.9637 33.7948V37.6183H45.975V33.7211C45.975 32.6287 45.5098 32.1362 44.5094 32.1362C43.5917 32.1362 42.7688 32.7197 42.7688 33.8403V37.6183H41.7802V31.3806Z"
            fill="black"
        />
        <path
            d="M48.3473 36.0524V32.2153H47.4534V31.3805H48.3473V29.9622H49.3369V31.3805H50.7934V32.2153H49.3369V35.9568C49.3369 36.5649 49.61 36.8626 50.1116 36.8626C50.3716 36.8682 50.6303 36.8237 50.8735 36.7315V37.5663C50.6019 37.6607 50.3153 37.7048 50.0278 37.6964C48.8954 37.6964 48.3473 37.0292 48.3473 36.0524Z"
            fill="black"
        />
        <path
            d="M29.1964 31.3804V32.3399C29.7635 31.6726 30.4945 31.2721 31.3949 31.2721C33.3466 31.2721 34.6084 32.6276 34.6084 34.512C34.6084 36.38 33.2957 37.7046 31.5451 37.7046C30.4272 37.7046 29.5633 37.243 29.1782 36.543V40.0278H28.1932V31.3795L29.1964 31.3804ZM31.3985 36.8643C32.7385 36.8643 33.5979 35.863 33.5979 34.5575C33.5979 33.121 32.7385 32.0941 31.3985 32.0941C30.0585 32.0941 29.1764 33.1438 29.1764 34.5566C29.1764 35.8757 30.0585 36.8643 31.3985 36.8643Z"
            fill="black"
        />
        <path d="M27.3649 24.907L21.1017 13.9501H33.6299L27.3649 24.907Z" fill="#006196" />
        <path d="M33.6271 13.9482H21.0989L27.3621 2.9895L33.6271 13.9482Z" fill="#2BA8E0" />
        <path d="M29.0719 0H41.5991L33.6273 13.9483L27.3641 2.98957L29.0719 0Z" fill="#006196" />
        <path d="M25.6551 0L27.363 2.98957L21.0998 13.9483L13.127 0H25.6551Z" fill="#6ECFF5" />
    </svg>
);

const handleHelpClick = () => {
    // TODO: track metrics
    window.open(`${__vistaRoute('/customer-care/help-center/')}`, '_blank');
};

export const ExitButton: React.FC<{ url: string; hideExitPopUp: boolean }> = ({
    url,
    hideExitPopUp,
}) => {
    const { pageData } = usePage();
    const { tracking } = useTracking();

    const screenClass = useScreenClass();

    const [isOpen, setIsOpen] = React.useState(false);

    const openExitModal = () => {
        setIsOpen(true);
    };

    const closeExitModal = () => {
        tracking.track('Pop-Up Closed', {
            category: 'Pop-Up',
            label: 'Design Brief Exit Modal Pop-Up',
            pageSection: pageData.pageSection,
            pageStage: pageData.pageStage,
            pageName: pageData.pageName,
        });
        setIsOpen(false);
    };

    const handleExitConfirmation = useCallback(() => {
        tracking.track('Navigation Clicked', {
            category: pageData.pageSection,
            label: 'Content: Exit Confirmation Pop-Up',
            eventDetail: `${window.location.pathname}${window.location.search};${url};Exit Confirmation Pop-Up;designBriefExitConfirmationButton`,
            navigationDetail: 'designBriefExitConfirmationButton',
            pageSection: pageData.pageSection,
            pageStage: pageData.pageStage,
            pageName: pageData.pageName,
        });

        window.location.href = url;
    }, [pageData, tracking, url]);

    useEffect(() => {
        if (isOpen) {
            tracking.track('Pop-Up Viewed', {
                category: 'Pop-Up',
                label: 'Design Brief Exit Modal Pop-Up',
                pageSection: pageData.pageSection,
                pageStage: pageData.pageStage,
                pageName: pageData.pageName,
            });
        }
    }, [isOpen, pageData, tracking]);

    return (
        <>
            <Button
                skin={screenClass === 'xs' ? 'tertiary' : undefined}
                onClick={hideExitPopUp ? handleExitConfirmation : () => openExitModal()}
            >
                {__('Exit')}
            </Button>
            <ModalDialog isOpen={isOpen} onRequestDismiss={closeExitModal}>
                <ModalDialogContent aria-labelledby="modal dialog button">
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText={__('Close')} />
                    </ModalDialogNav>
                    <ModalDialogHeader>
                        <ModalDialogTitle>{__('Are you sure?')}</ModalDialogTitle>
                    </ModalDialogHeader>
                    <ModalDialogBody>
                        {__('Are you sure you want to exit your brief? All progress will be lost.')}
                    </ModalDialogBody>
                    <ModalDialogFooter>
                        <ModalDialogButtons>
                            <Button skin="secondary" onClick={closeExitModal}>
                                {__('Go back')}
                            </Button>
                            <Button onClick={handleExitConfirmation} skin="primary">
                                {__('Yes, exit')}
                            </Button>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

const MinHeightLink: React.FC<React.ComponentProps<typeof Link>> = ({ ...props }) => (
    <Link
        style={{
            display: 'flex',
            alignItems: 'center',
            height: 'fit-content',
            padding: tokensRaw.SwanSemSpace2,
        }}
        {...props}
    />
);

export type TopBarProps = {
    title: string;
    exitUrl?: string;
    hideExitPopUp?: boolean;
};

const DesktopTopBar: React.FC<TopBarProps> = ({ title, exitUrl, hideExitPopUp = false }) => (
    <FlexBox
        backgroundColor={'standard'}
        style={{ borderBottom: `1px solid ${tokensRaw.SwanBaseColorGrey300}`, width: '100%' }}
        alignItems="center"
        justifyContent="space-between"
        paddingLeft={6}
        paddingRight={3}
        paddingY={3}
    >
        <FlexBox alignItems="center" gap={2}>
            <MinHeightLink to="/">
                <VistaLogo />
            </MinHeightLink>
            <H1 margin={0} fontSize="standard">
                {title}
            </H1>
        </FlexBox>
        <FlexBox gap={5} paddingY={4} paddingX={6}>
            <Link
                render={(p) => (
                    <Button
                        skin="tertiary"
                        iconPosition={'left'}
                        className={p.className}
                        onClick={handleHelpClick}
                    >
                        {p.children}
                    </Button>
                )}
            >
                <Icon iconType="helpQuery" /> {__('Help center')}
            </Link>
            {exitUrl && <ExitButton url={exitUrl} hideExitPopUp={hideExitPopUp} />}
        </FlexBox>
    </FlexBox>
);

const MobileTopBar: React.FC<TopBarProps> = ({ exitUrl, hideExitPopUp = false }) => (
    <FlexBox
        backgroundColor={'standard'}
        style={{
            width: '100%',
            alignContent: 'space-between',
        }}
        alignItems="center"
        justifyContent="space-between"
        paddingX={5}
        paddingY={3}
    >
        <Link
            to={__vistaRoute('/customer-care/help-center/')}
            render={(p) => (
                <Button
                    style={{ width: tokensRaw.SwanSemSpace8 }}
                    onClick={handleHelpClick}
                    skin="unstyled"
                    className={p.className}
                >
                    {p.children}
                </Button>
            )}
        >
            <Icon iconType="helpQuery" />
        </Link>
        <MinHeightLink to="/">
            <VistaLogoWithText />
        </MinHeightLink>
        {exitUrl ? <ExitButton url={exitUrl} hideExitPopUp={hideExitPopUp} /> : <Box pr={8} />}
    </FlexBox>
);

export const TopBar: React.FC<TopBarProps> = ({ title, exitUrl, hideExitPopUp }) => {
    const screenClass = useScreenClass();

    const BarComponent = screenClass === 'xs' ? MobileTopBar : DesktopTopBar;

    return <BarComponent title={title} exitUrl={exitUrl} hideExitPopUp={hideExitPopUp} />;
};
