import { ApolloError } from '@apollo/client';

export type ServerApolloErrorType =
    | 'INVALID_INPUT'
    | 'PERMISSION_DENIED'
    | 'NOT_FOUND'
    | 'GONE'
    | 'UNAUTHENTICATED';

export type ClientApolloErrorType = ServerApolloErrorType | 'NETWORK_ERROR' | 'UNKNOWN_ERROR';

export function getApolloErrorType({
    graphQLErrors,
    networkError,
}: ApolloError): ClientApolloErrorType {
    if (networkError) {
        return 'NETWORK_ERROR';
    }

    if (graphQLErrors && graphQLErrors.length > 0) {
        const graphQLError = graphQLErrors[0];
        if (graphQLError.extensions) {
            return getGraphQLErrorType(graphQLError.extensions['type']);
        }
    }

    return 'UNKNOWN_ERROR';
}

export function getGraphQLErrorType(errorType: string): ClientApolloErrorType {
    switch (errorType) {
        case 'INVALID_INPUT':
            return 'INVALID_INPUT';
        case 'PERMISSION_DENIED':
            return 'PERMISSION_DENIED';
        case 'NOT_FOUND':
            return 'NOT_FOUND';
        case 'GONE':
            return 'GONE';
        case 'UNAUTHENTICATED':
            return 'UNAUTHENTICATED';
        default:
            return 'UNKNOWN_ERROR';
    }
}
