import {
    Card,
    Column,
    FlexBox,
    GridContainer,
    Row,
    Spinner,
    Typography,
    responsive,
    tokensRaw,
} from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useIsMultiStep } from '../../ViewContext';
import { useWorkEntityFieldQuery } from './WorkEntityField.generated';
import WorkEntityViews from './WorkEntityViews';

const ResponsiveCard = responsive(Card);
const ResponsiveFlexBox = responsive(FlexBox);

interface WorkEntityFieldProps {
    workEntityValue: string;
    title?: string;
    description?: string | null;
}

export default function WorkEntityField(props: WorkEntityFieldProps) {
    const isMultiStep = useIsMultiStep();
    const { data, error } = useWorkEntityFieldQuery({
        variables: {
            workId: props.workEntityValue,
        },
    });

    if (error) {
        throw error;
    }

    let workEntityViews = <Spinner accessibleText={__('Loading…')} />;
    if (data) {
        workEntityViews = <WorkEntityViews views={data.workEntityViews.views} />;
    }

    return isMultiStep ? (
        <ResponsiveCard
            mt={5}
            p={4}
            style={{
                borderRadius: tokensRaw.SwanSemBorderRadiusSubtle,
            }}
            swanStyle={{
                backgroundColor: 'info',
            }}
            sm={{
                style: {
                    minWidth: 630,
                    minHeight: 104,
                },
            }}
        >
            <GridContainer>
                <Row>
                    <Column pl={2} span={8}>
                        <Typography fontSkin="title-item">{props.title}</Typography>
                        <Typography fontSkin="body-small">{props.description}</Typography>
                    </Column>
                    <Column pr={2} span={4}>
                        <ResponsiveFlexBox
                            style={{ height: '100%', minHeight: '104px' }}
                            alignItems="center"
                            sm={{
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {workEntityViews}
                        </ResponsiveFlexBox>
                    </Column>
                </Row>
            </GridContainer>
        </ResponsiveCard>
    ) : (
        workEntityViews
    );
}
