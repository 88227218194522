import { useUpdateField } from '../useUpdateField';
import { useBriefSetDateMutation } from './briefSetDate.generated';

export function useUpdateDateField(key: string, briefId?: string, initialValue?: string) {
    const [setDate] = useBriefSetDateMutation();
    return useUpdateField<string>({
        key,
        setField: setDate,
        briefId,
        initialValue: initialValue || null,
    });
}
