import React from 'react';
import styled from 'styled-components';
import { StepProvider, useStepContext } from '../context/StepContext';
import { TopBar, TopBarProps } from './TopBar';

const ShellOuter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    width: 100vw;
    overflow-x: hidden;
`;

const StepWrapper = ({ stepIndex, children }: { stepIndex: number; children: React.ReactNode }) => {
    const { currentStep } = useStepContext();
    return (
        <div
            style={{
                display: currentStep === stepIndex ? 'flex' : 'none',
                width: '100%',
                justifyContent: 'center',
            }}
        >
            {children}
        </div>
    );
};

function useScrollToTopOnStepChange() {
    const { currentStep } = useStepContext();
    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        ref.current?.scrollTo(0, 0);
    }, [currentStep]);
    return ref;
}

export const MultiStepLayout = ({
    children,
    title,
    exitUrl,
    hideExitPopUp,
}: {
    children: React.ReactNode;
} & TopBarProps) => {
    const ref = useScrollToTopOnStepChange();
    return (
        <ShellOuter ref={ref}>
            <TopBar title={title} exitUrl={exitUrl} hideExitPopUp={hideExitPopUp} />
            {children}
        </ShellOuter>
    );
};

export type MultiStepProps = {
    steps: JSX.Element[];
    validate: (stepIndex: number) => Promise<boolean>;
    Layout?: React.ComponentType<{ children: React.ReactNode } & TopBarProps>;
    trackSteps?: boolean;
} & TopBarProps;

export const MultiStep = ({
    steps,
    validate,
    title,
    exitUrl,
    Layout = MultiStepLayout,
    trackSteps,
    hideExitPopUp,
}: MultiStepProps) => {
    return (
        <StepProvider totalSteps={steps.length + 1} validate={validate} trackSteps={trackSteps}>
            <Layout title={title} exitUrl={exitUrl} hideExitPopUp={hideExitPopUp}>
                {steps.map((s, i) => {
                    return (
                        <StepWrapper stepIndex={i + 1} key={i + 1}>
                            {s}
                        </StepWrapper>
                    );
                })}
            </Layout>
        </StepProvider>
    );
};
