import React from 'react';
import { useNavigationClicked } from './useNavigationClicked';

export function useSteps(stepCount: number, trackStep = true) {
    const [currentStep, setCurrentStep] = React.useState(() => {
        if (typeof window !== 'undefined' && trackStep) {
            const params = new URLSearchParams(window.location.search);
            const stepParam = params.get('step');
            return stepParam ? Math.min(parseInt(stepParam, 10), stepCount - 1) : 1;
        }
        return 1;
    });
    const { trackNavigationClick } = useNavigationClicked({
        label: 'Content: Bottom Bar',
    });

    React.useEffect(() => {
        if (typeof window !== 'undefined' && trackStep) {
            const url = new URL(window.location.href);
            url.searchParams.set('step', currentStep.toString());
            window.history.replaceState({}, '', url.toString());
        }
    }, [currentStep, trackStep]);

    const goToNextStep = () => {
        if (currentStep < stepCount - 1) {
            trackNavigationClick(
                `${window.location.pathname}${window.location.search};${
                    window.location.pathname
                }?step=${currentStep + 1};Bottom Bar;designBriefNextButton`,
                'designBriefNextButton'
            );
            setCurrentStep((currentStep: number) => currentStep + 1);
        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            trackNavigationClick(
                `${window.location.pathname}${window.location.search};${
                    window.location.pathname
                }?step=${currentStep - 1};Bottom Bar;designBriefBackButton`,
                'designBriefBackButton'
            );

            setCurrentStep((currentStep: number) => currentStep - 1);
        }
    };

    return { currentStep, goToNextStep, goToPreviousStep };
}
