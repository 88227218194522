import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, Column, GridContainer, H1, Row, Typography } from '@vp/swan';
import { Maybe } from '@99designs/graph-utils/types';
import { __ } from '@99designs/i18n';
import { StepLayout } from '@99designs/swan-wizard';
import { NormalisedBriefProduct } from '../BriefContext';
import { EditFormGroupModal } from './EditFormGroupModal';
import { FieldReview } from './Field';
import { FieldGroupData } from './FieldGroup';
import { reviewGroupTitleWithOverrides } from './FieldGroup/useGroupTitleWithOverrides';
import { Information } from './Information';
import { StepProductCard } from './StepProductCard';
import { BriefProductFragment, Field_WorkEntityField_Fragment } from './brief.generated';

export function Review({
    groups,
    product,
    workEntity,
    submitButton,
    groupFormSections,
    briefId,
    isEditMode,
    groupFormKey,
    setGroupFormKey,
}: {
    groups: FieldGroupData[];
    workEntity?: {
        title: string;
        description: string | null;
        field: Field_WorkEntityField_Fragment;
    };
    product: Maybe<BriefProductFragment | NormalisedBriefProduct>;
    submitButton: React.ComponentType;
    groupFormSections: Record<string, React.ReactNode>;
    briefId: string;
    isEditMode: boolean;
    groupFormKey: number;
    setGroupFormKey: React.Dispatch<React.SetStateAction<number>>;
}) {
    const nav = useNavigate();
    return (
        <StepLayout
            key={'review'}
            NextAction={submitButton}
            PreviousAction={() =>
                isEditMode ? (
                    <span />
                ) : (
                    <Button
                        skin="tertiary"
                        onClick={() => {
                            const currentPath = window.location.pathname;
                            const newPath = currentPath.replace(/\/review$/, '');
                            const url = new URL(newPath, window.location.origin);
                            url.searchParams.set('step', groups.length.toString());
                            nav(url.pathname + url.search);
                        }}
                    >
                        {__('Back')}
                    </Button>
                )
            }
            Information={() => <Information product={product} />}
            showProgressBar={false}
            maxWidth={'894px'}
        >
            <Box mb={10} mx={5} mt={5}>
                <H1 fontSkin="title-headline">{__('Give your brief a once-over')}</H1>
                <Typography mb="6">
                    {__('Take a moment to review your design project details')}
                </Typography>
                {product && <StepProductCard workEntity={workEntity} product={product} />}
                {groups.map((group) => (
                    <ReviewCard
                        key={`${group.id}-card`}
                        group={group}
                        briefId={briefId}
                        groupFormSections={groupFormSections}
                        isEditMode={isEditMode}
                        groupFormKey={groupFormKey}
                        setGroupFormKey={setGroupFormKey}
                    />
                ))}
            </Box>
        </StepLayout>
    );
}

interface ReviewCardProps {
    group: FieldGroupData;
    briefId: string;
    groupFormSections: Record<string, React.ReactNode>;
    isEditMode: boolean;
    groupFormKey: number;
    setGroupFormKey: React.Dispatch<React.SetStateAction<number>>;
}

function ReviewCard({
    group,
    briefId,
    groupFormSections,
    isEditMode,
    setGroupFormKey,
}: ReviewCardProps) {
    const {
        formState: { errors },
    } = useFormContext();

    const groupErrors = group.fields.flatMap((f) => {
        return errors[f.id] != null ? [errors[f.id]] : [];
    });
    const groupHasErrors = groupErrors.length > 0;

    return (
        <Card my="6" p="5" bordered style={{ position: 'relative' }}>
            <GridContainer>
                <Row>
                    <Column pl={0} span={11} spanXs={11}>
                        <Typography fontSkin="title-subsection">
                            {reviewGroupTitleWithOverrides(group)}
                        </Typography>
                        {groupHasErrors && (
                            <Typography mt={5} textColor={'error'} fontSkin="body-standard">
                                {__('This section has missing required fields')}
                            </Typography>
                        )}
                        {group.fields.map((field) => {
                            return (
                                <FieldReview
                                    key={`field-review-${field.id}`}
                                    skin="standard"
                                    field={field}
                                    colWidthOverride={6}
                                    showTitle={group.fields.length > 1}
                                />
                            );
                        })}
                    </Column>
                    <EditFormGroupModal
                        briefId={briefId}
                        groupHasErrors={groupHasErrors}
                        showEditButton={group.id !== 'reviewingYourDesign' || !isEditMode}
                        setGroupFormKey={setGroupFormKey}
                    >
                        {groupFormSections[group.id]}
                    </EditFormGroupModal>
                </Row>
            </GridContainer>
        </Card>
    );
}
