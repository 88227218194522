import { Box, responsive, tokensRaw } from '@vp/swan';
import styled from 'styled-components';
import { useStepContext } from '../context/StepContext';

const ResponsiveBox = responsive(Box);

const ProgressIndicator = styled.div`
    width: 100%;
    height: ${tokensRaw.SwanSemSpace3};
    background-color: ${tokensRaw.SwanBaseColorGrey200};
    border-radius: ${tokensRaw.SwanSemBorderRadiusStandard};
`;

const ProgressBarInner = styled.div`
    height: ${tokensRaw.SwanSemSpace3};
    background-color: ${tokensRaw.SwanBaseColorBlue400};
    border-radius: ${tokensRaw.SwanSemBorderRadiusStandard};
`;

export const ProgressBar = () => {
    const { currentStep, totalSteps } = useStepContext();
    return (
        <ResponsiveBox
            padding={5}
            xs={{
                style: {
                    width: '100%',
                },
            }}
            sm={{
                style: {
                    width: '300px',
                    alignSelf: 'flex-start',
                },
            }}
        >
            <ProgressIndicator>
                <ProgressBarInner
                    style={{
                        width: `${(currentStep / (totalSteps - 1)) * 100}%`,
                    }}
                />
            </ProgressIndicator>
        </ResponsiveBox>
    );
};
