import React from 'react';
import {
    Box,
    Column,
    FlexBox,
    ResponsiveImage,
    Typography,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import styled from 'styled-components';
import { PreviewModal, PreviewModalVariant } from '@99designs/design-services-common';
import { View, ViewLink } from '@99designs/graph-utils/types';
import { useIsMultiStep } from '../../ViewContext';

export interface WorkEntityViewsProps {
    views: View[];
}

const StyledResponsiveImage = styled(ResponsiveImage)`
    object-fit: cover;
    cursor: zoom-in;
    width: 100%;
`;

const StyledBox = styled(Box)`
    width: 100%;
    height: 100%;
    display: inline-block;
`;

const StyledColumn = styled(Column)`
    display: inline-block;
`;

export default function WorkEntityViews({ views }: WorkEntityViewsProps) {
    const [isOpen, setIsOpen] = React.useState(false);
    const isMultiStep = useIsMultiStep();
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const images = views.map((view) => {
        return {
            url: view._links.image.href,
            title: view._links.image.title,
        };
    });

    return (
        <>
            <PreviewModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                currentSlide={currentSlide}
                setCurrentSlide={setCurrentSlide}
                previewFiles={images}
                variant={PreviewModalVariant.WORK_ENTITY}
            />
            {views.map(({ _links }, i) =>
                isMultiStep ? (
                    <MultiStepWorkEntityView
                        _links={_links}
                        key={i}
                        setCurrentSlide={setCurrentSlide}
                        i={i}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                    />
                ) : (
                    <SingleStepWorkEntityView
                        _links={_links}
                        key={i}
                        setCurrentSlide={setCurrentSlide}
                        i={i}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                    />
                )
            )}
        </>
    );
}

type EntityViewProps = {
    _links: ViewLink;
    setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
    i: number;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
};

const MultiStepWorkEntityView = ({
    _links,
    setCurrentSlide,
    i,
    setIsOpen,
    isOpen,
}: EntityViewProps) => {
    const screenClass = useScreenClass();
    return (
        <FlexBox
            style={{
                height: '80px',
                width: '80px',
                borderRadius: tokensRaw.SwanSemBorderRadiusStandard,
            }}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            py={'4'}
            px={'1'}
            mr="5"
            {...(screenClass === 'xs' ? { mt: 4 } : {})}
            backgroundColor="standard"
        >
            <StyledResponsiveImage
                src={_links.image.href}
                alt={_links.image.title}
                pt={4}
                role="button"
                tabIndex={0}
                onClick={() => {
                    setCurrentSlide(i);
                    setIsOpen(!isOpen);
                }}
            />
            <Typography fontSize="xsmall" textAlign="center">
                {_links.image.title}
            </Typography>
        </FlexBox>
    );
};

const SingleStepWorkEntityView = ({
    _links,
    setCurrentSlide,
    i,
    setIsOpen,
    isOpen,
}: EntityViewProps) => (
    <StyledColumn mt={4} mr={5} span={5}>
        <FlexBox flexDirection="column" alignItems="center">
            <StyledBox py={'3'} align="center">
                <StyledResponsiveImage
                    src={_links.image.href}
                    alt={_links.image.title}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        setCurrentSlide(i);
                        setIsOpen(!isOpen);
                    }}
                />
            </StyledBox>
            <Typography fontSize="small" fontWeight="bold" textAlign="center">
                {_links.image.title}
            </Typography>
        </FlexBox>
    </StyledColumn>
);
