import { useCallback } from 'react';
import { NotifiableError } from '@bugsnag/js';
import { bugtracker } from '@99designs/design-services-common';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';
import { __url } from '@99designs/i18n';
import { BRIEF_PAGE_NAME, sendAddToCartTrackingEvent } from '@99designs/tracking';
import {
    useCreateRequestWithEventMutation,
    useDesignLiveAppointmentUpdatedMutation,
    useRequestLazyQuery,
} from '../../graph';
import { ClaimBriefMutation } from '../../graph/ClaimBrief.generated';
import { UpsertToCartRequest, useCart } from '../../lib/hooks';
import { DesignServiceRequestType, MERCHANT_SERVICE_CONFIG } from './consts';
import { getWorkIdFromDynamicFields } from './getWorkIdFromDynamicFields';
import { designLiveDataFromBrief, getRequestType } from './requestDataFromBrief';

/*
 *  Note: Experiments are used in `requests` to direct traffic between the new and old stacks
 *  Remove experiments when revision requests are no longer going through MCP,
 *  as there will be no need to send traffic to the old stack anymore
 */

const experiments = JSON.stringify([
    'useRequests',
    'useCollaborate',
    'useCollaborateForDesignLive',
]);

export const useSubmitToRequests = (
    locale: string,
    accessToken: string,
    shopperId: string,
    briefId: string
) => {
    const [requestQuery] = useRequestLazyQuery();
    const [designLiveAppointmentUpdated] = useDesignLiveAppointmentUpdatedMutation();
    const [createRequestWithEvent] = useCreateRequestWithEventMutation();
    const { upsertToCart, requestIdByBriefId } = useCart(locale, { accessToken, shopperId });

    return useCallback(
        async (data: ClaimBriefMutation) => {
            try {
                const product = data.claimStructuredBrief.product;
                if (!product)
                    throw new Error('Product is not present in claim structured brief response');

                const cartConfig = product.cimpressProductConfig;
                if (!cartConfig)
                    throw new Error(
                        'Cart config is not present in claim structured brief response'
                    );

                const experience = collaborationExperienceForRequest(
                    product.fulfilmentStrategy,
                    data.claimStructuredBrief.dynamicFields
                );
                const expertProductPrd = prdForCart(
                    product.fulfilmentStrategy,
                    cartConfig.cimpressProductKey,
                    experience
                );
                const briefDesignLiveData = designLiveDataFromBrief(
                    data.claimStructuredBrief.dynamicFields
                );

                let expertRequestID = await requestIdByBriefId(briefId);
                const hasRequestForBrief = expertRequestID.length > 0;

                if (hasRequestForBrief && experience === 'DesignLive') {
                    const { data } = await requestQuery({
                        variables: {
                            id: expertRequestID,
                        },
                    });

                    if (!data) throw new Error(`Failed to call get request`);

                    if (
                        data.request.facts?.designLiveAppointmentTime !==
                        briefDesignLiveData.designLiveAppointmentTime
                    ) {
                        const { errors } = await designLiveAppointmentUpdated({
                            variables: {
                                requestId: expertRequestID,
                                input: {
                                    timezone: briefDesignLiveData.timezone!,
                                    appointmentTime: briefDesignLiveData.designLiveAppointmentTime!,
                                },
                            },
                        });

                        if (errors && errors.length > 0) {
                            throw errors;
                        }
                    }
                }

                if (!hasRequestForBrief) {
                    const purchaseIntentionInitiatedEvent = {
                        withDesignLive: experience === 'DesignLive',
                        ...briefDesignLiveData,
                    };

                    const resp = await createRequestWithEvent({
                        variables: {
                            input: {
                                facts: {
                                    locale,
                                },
                                purchaseIntentionInitiatedEvent,
                            },
                        },
                    });

                    if (!resp.data) throw new Error(`Failed to call create new request`);

                    expertRequestID = resp.data?.createRequestWithEvent?.publicId;

                    sendAddToCartTrackingEvent({
                        pageName: BRIEF_PAGE_NAME,
                        pageStage: 'Design',
                        pageSection: 'Design Services',
                        product_id: expertProductPrd, // should be mpvId
                        name: product.title,
                        core_product_id: expertProductPrd,
                        price: Math.round(product.pricing.amount.amountInCents / 100),
                    });
                }

                const cartProduct: UpsertToCartRequest['product'] = {
                    productKey: expertProductPrd,
                    productVersion: cartConfig.cimpressProductVersion,
                    productName: product.title,
                    fulfilmentData: {
                        productBriefID: briefId,
                        expertRequestID,
                        postPaymentUrl: __url('/graphic-design/dashboard'),
                        printProductMPVId: data.claimStructuredBrief.fulfilment?.mpvId || '',
                        experiments,
                        fulfilmentStrategy:
                            data.claimStructuredBrief.fulfilmentStrategy ?? undefined,
                        ...(experience ? { withDesignLive: experience === 'DesignLive' } : {}),
                    },
                };

                if (data.claimStructuredBrief.fulfilment?.size) {
                    cartProduct.selectedAttributes = [
                        {
                            value: data.claimStructuredBrief.fulfilment?.size,
                            key: 'ProjectSize',
                        },
                    ];
                }

                // NOTE: Can't use requestID as the correlationID until the brief has a requestID on it (or it's the same ID))
                await upsertToCart({
                    product: cartProduct,
                    correlationId: briefId,
                    workId: getWorkIdFromDynamicFields(data.claimStructuredBrief.dynamicFields),
                });

                return;
            } catch (e) {
                bugtracker.notify(e as NotifiableError, (event) => {
                    event.context = `Error during submission to requests for: ${briefId}.`;
                });
                throw e;
            }
        },
        [
            requestIdByBriefId,
            briefId,
            upsertToCart,
            requestQuery,
            designLiveAppointmentUpdated,
            createRequestWithEvent,
            locale,
        ]
    );
};

// Note: this function accounts for the fact that there is one expert product for two PRDs for CARE products
function prdForCart(
    expertType: FulfilmentStrategy,
    defaultPRD: string,
    collaborationExperience: DesignServiceRequestType | null
): string {
    if (expertType === 'FULFILMENT_STRATEGY_COMMUNITY') return defaultPRD;
    if (collaborationExperience && MERCHANT_SERVICE_CONFIG[collaborationExperience])
        return MERCHANT_SERVICE_CONFIG[collaborationExperience];

    return defaultPRD;
}

function collaborationExperienceForRequest(
    expertType: FulfilmentStrategy,
    briefData: ClaimBriefMutation['claimStructuredBrief']['dynamicFields']
): DesignServiceRequestType | null {
    switch (expertType) {
        case 'FULFILMENT_STRATEGY_COMMUNITY':
            return null;
        case 'FULFILMENT_STRATEGY_CARE':
            return getRequestType(briefData);
    }
    return null;
}
