import Bugsnag from '@bugsnag/js';
import { isFeatureEnabled, waitTillAvailable } from '@vp/ab-reader';
import { FulfilmentStrategy } from '@99designs/graph-utils/types';

export async function isMultiStepBriefEnabled(fulfillmentStrategy?: FulfilmentStrategy) {
    const available = await waitTillAvailable(1000);
    if (!available) {
        Bugsnag.notify(new Error('AB reader is not available'));
        return false;
    }
    if (fulfillmentStrategy !== 'FULFILMENT_STRATEGY_CARE') {
        return false;
    }
    return isFeatureEnabled('expert_services_multistep_brief');
}
