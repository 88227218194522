import { PropsWithChildren } from 'react';
import { FlexBox, responsive } from '@vp/swan';

const ResponsiveFlexBox = responsive(FlexBox);

export default function StepItemContainer({
    children,
    isModal,
}: PropsWithChildren & { isModal: boolean }) {
    return (
        <ResponsiveFlexBox
            marginBottom={isModal ? 1 : 3}
            flexDirection="row"
            sm={{
                flexDirection: isModal ? 'row' : 'column',
                alignItems: 'center',
            }}
            md={{
                flexDirection: 'row',
            }}
        >
            {children}
        </ResponsiveFlexBox>
    );
}
